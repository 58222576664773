(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('ReturnToSenderDialogController', ReturnToSenderDialogController);

    ReturnToSenderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'ReturnToSender', 'Vendor', 'PrintJobRecord', 'VendorStaff'];

    function ReturnToSenderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, ReturnToSender, Vendor, PrintJobRecord, VendorStaff) {
        var vm = this;

        vm.returnToSender = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        Vendor.current(function(resp){
            vm.returnToSender.vendor = resp;
        });
        VendorStaff.current(function(resp){
            vm.returnToSender.vendorStaff = resp;
        });
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.returnToSender.id !== null) {
                ReturnToSender.update(vm.returnToSender, onSaveSuccess, onSaveError);
            } else {
                ReturnToSender.save(vm.returnToSender, onSaveSuccess, onSaveError);
            }
        }

        vm.validateBarcode = function() {
            ReturnToSender.validateBarcode({barcode:vm.barcode}, function(resp){
                vm.returnToSender.printJobRecord = resp;
                vm.barcodePreviewUrl = "/api/barcodes/4state/"+vm.barcode+".png";
            }, function(resp){
                delete vm.barcodePreviewUrl;
                swal("Invalid Barcode","Invalid code or print job record not found","error");
            });
        };

        vm.validatePrintJobRecordId = function() {
            ReturnToSender.validatePrintJobRecordId({printJobRecordId:vm.printJobRecordId}, function(resp){
                vm.returnToSender.printJobRecord = resp;
                vm.barcodePreviewUrl = "/api/barcodes/4state/"+resp.c2p_address_barcode+".png";
            }, function(resp){
                delete vm.barcodePreviewUrl;
                swal("Invalid Barcode","Invalid code or print job record not found","error");
            });
        };

        function onSaveSuccess (result) {
            $scope.$emit('crm2PrintApp:returnToSenderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.created = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
