(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('VoucherCodeDetailController', VoucherCodeDetailController);

    VoucherCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'VoucherCode', 'User', 'PrintJobCharge'];

    function VoucherCodeDetailController($scope, $rootScope, $stateParams, entity, VoucherCode, User, PrintJobCharge) {
        var vm = this;

        vm.voucherCode = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:voucherCodeUpdate', function(event, result) {
            vm.voucherCode = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
