(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('VendorJobTicket', VendorJobTicket);

    VendorJobTicket.$inject = ['$resource', 'DateUtils'];

    function VendorJobTicket ($resource, DateUtils) {
        var resourceUrl =  '/api/vendor-job-tickets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                        data.send = DateUtils.convertDateTimeFromServer(data.send);
                        data.received = DateUtils.convertDateTimeFromServer(data.received);
                        data.started = DateUtils.convertDateTimeFromServer(data.started);
                        data.finished = DateUtils.convertDateTimeFromServer(data.finished);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'sendUndelivered': {
                method: 'POST',
                url: '/api/vendor-job-tickets/deliver-unsent'
            },
            'markJobTicketsComplete': {
                method: 'POST',
                url: '/api/vendor-job-tickets/mark-all-complete'
            }
        });
    }
})();
