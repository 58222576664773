(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('IndustryController', IndustryController);

    IndustryController.$inject = ['$scope', '$state', 'Industry', 'IndustrySearch'];

    function IndustryController ($scope, $state, Industry, IndustrySearch) {
        var vm = this;
        
        vm.industries = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            Industry.query(function(result) {
                vm.industries = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            IndustrySearch.query({query: vm.searchQuery}, function(result) {
                vm.industries = result;
            });
        }    }
})();
