(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('Invoice', Invoice);

    Invoice.$inject = ['$resource', 'DateUtils'];

    function Invoice ($resource, DateUtils) {
        var resourceUrl =  'api/invoices/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.raised = DateUtils.convertDateTimeFromServer(data.raised);
                        data.issued = DateUtils.convertDateTimeFromServer(data.issued);
                        data.paid = DateUtils.convertDateTimeFromServer(data.paid);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'charges': {
                method:'GET',
                url:'/api/invoices/:invoiceId/charges',
                isArray: true,
                params: {invoiceId:"@invoiceId"}
            },
            'issue': {
                method:'PUT',
                url:'/api/invoices/:invoiceId/issue',
                params: {invoiceId:"@invoiceId"}
            },
            'updateTotals': {
                method:'PUT',
                url:'/api/invoices/:invoiceId/update-totals',
                params: {invoiceId:"@invoiceId"}
            },
            'pay': {
                method:'PUT',
                url:'/api/invoices/:invoiceId/pay',
                params: {invoiceId:"@invoiceId"}
            },
            'assignChargesToCostCenter': {
                method:'POST',
                url:'/api/invoices/:invoiceId/assign-charges-to-cost-center',
                params: {invoiceId:"@invoiceId"}
            },
            'history': {
                method:'GET',
                url:'/api/invoices/:invoiceId/history',
                isArray: true,
                params: {invoiceId:"@invoiceId"}
            }
        });
    }
})();
