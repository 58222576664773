(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('TemplateController', TemplateController);

    TemplateController.$inject = ['$timeout','$scope', '$state', 'Template', 'TemplateSearch', 'ParseLinks', 'AlertService'];

    function TemplateController ($timeout, $scope, $state, Template, TemplateSearch, ParseLinks, AlertService) {
        var vm = this;

        vm.templates = [];
        vm.categories = [];
        vm.industry = null;
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.clear = clear;
        vm.search = search;

        loadAll();

        function loadAll() {
            if(vm.loading) return;
            vm.loading = true;
            if (vm.currentSearch) {
                TemplateSearch.query({
                    query: vm.currentSearch,
                    industry: vm.industry,
                    // page: vm.page,
                    // size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Template.query({
                    // page: vm.page,
                    industry: vm.industry,
                    // size: 20,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                if(data.length > 0) {
                    vm.categories = {};
                    for (var i = 0; i < data.length; i++) {
                        vm.templates.push(data[i]);
                        vm.categories[data[i].category || "Other"] = (vm.categories[data[i].category || "Other"] || 0) + 1;
                    }
                }
                //prevent infinite scroll from going crazy... update after render
                $timeout(function(){
                    vm.loading = false;
                });
            }
            function onError(error) {
                vm.loading = false;
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.templates = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear () {
            vm.templates = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            loadAll();
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.templates = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            loadAll();
        }

        vm.showIndustry = function(industry) {
            vm.industry = industry;
            vm.clear();
        }
    }
})();
