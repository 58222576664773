(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('postal-account', {
            parent: 'entity',
            url: '/postal-account',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'crm2PrintApp.postalAccount.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/postal-account/postal-accounts.html',
                    controller: 'PostalAccountController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('postalAccount');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('postal-account-detail', {
            parent: 'entity',
            url: '/postal-account/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'crm2PrintApp.postalAccount.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/postal-account/postal-account-detail.html',
                    controller: 'PostalAccountDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('postalAccount');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PostalAccount', function($stateParams, PostalAccount) {
                    return PostalAccount.get({id : $stateParams.id});
                }]
            }
        })
        .state('postal-account.new', {
            parent: 'postal-account',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/postal-account/postal-account-dialog.html',
                    controller: 'PostalAccountDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                created: null,
                                provider: null,
                                accountNumber: null,
                                country: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('postal-account', null, { reload: true });
                }, function() {
                    $state.go('postal-account');
                });
            }]
        })
        .state('postal-account.edit', {
            parent: 'postal-account',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/postal-account/postal-account-dialog.html',
                    controller: 'PostalAccountDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PostalAccount', function(PostalAccount) {
                            return PostalAccount.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('postal-account', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('postal-account.delete', {
            parent: 'postal-account',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/postal-account/postal-account-delete-dialog.html',
                    controller: 'PostalAccountDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PostalAccount', function(PostalAccount) {
                            return PostalAccount.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('postal-account', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
