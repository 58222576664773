(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PostalAccountDetailController', PostalAccountDetailController);

    PostalAccountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PostalAccount', 'Country'];

    function PostalAccountDetailController($scope, $rootScope, $stateParams, entity, PostalAccount, Country) {
        var vm = this;
        vm.postalAccount = entity;
        
        var unsubscribe = $rootScope.$on('crm2PrintApp:postalAccountUpdate', function(event, result) {
            vm.postalAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
