(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('SiteUserDialogController', SiteUserDialogController);

    SiteUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SiteUser', 'Site', 'User', 'SiteToken'];

    function SiteUserDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SiteUser, Site, User, SiteToken) {
        var vm = this;

        if(entity.$promise) {
            entity.$promise.then(function (resp) {
                vm.siteUser = resp;
                $timeout(vm.siteSelected);
            });
        } else {
            vm.siteUser = entity;
        }
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.sites = Site.queryWithApi();
        vm.users = User.query();

        vm.siteSelected = function() {
            if(!vm.siteUser.site) return;
            Site.authenticationMethod({siteId:vm.siteUser.site.id},function(resp){
                vm.siteAuthenticationMethod = resp.method;
                if(resp.method == 'OAUTH') {
                    Site.oauthLoginUrl({siteId:vm.siteUser.site.id},function(resp){
                        vm.siteOauthLoginUrl = resp.url;
                    });
                }
            });
        };

        var tokenCheck = function() {
            Site.oauthSiteToken({siteId:vm.siteUser.site.id},function(resp){
                vm.token = resp;
                if(vm.token.siteUser) {
                    vm.siteUser.username = vm.token.siteUser.username;
                    vm.siteUser.externalUserId = vm.token.siteUser.externalUserId;
                    vm.siteUser.externalSubUserId = vm.token.siteUser.externalSubUserId;
                    vm.siteUser.displayName = vm.token.siteUser.displayName;
                }
            },vm.startTokenCheck);
        }

        vm.startTokenCheck = function() {
            setTimeout(tokenCheck,3000);
            return true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.generateToken = function () {
            vm.isSaving = true;
            SiteToken.generate(vm.siteUser, function (resp) {
                vm.token = resp;
                vm.siteUser.externalUserId = vm.token.siteUser.externalUserId;
                vm.siteUser.externalSubUserId = vm.token.siteUser.externalSubUserId;
                vm.siteUser.displayName = vm.token.siteUser.displayName;
                vm.isSaving = false;
            }, function (resp) {
                vm.isSaving = false;
                swal('Authentication failed!',"Please verify your username and password and try again.",'error');
            });
        };

        function save () {
            vm.isSaving = true;
            if (vm.siteUser.id !== null) {
                SiteUser.update(vm.siteUser, onSaveSuccess, onSaveError);
            } else {
                SiteUser.save(vm.siteUser, onSaveSuccess, onSaveError);
            }
        }

        var onSaveSuccess = function (result) {
            $scope.$emit('crm2PrintApp:siteUserUpdate', result);
            if (vm.token) {
                vm.token.siteUser = result;
                SiteToken.save(vm.token, function () {
                    $uibModalInstance.close(result);
                    vm.isSaving = false;
                }, onSaveError);
            } else {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        };

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        // vm.siteSelected();
    }
})();
