(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .factory('AdminDashboard', AdminDashboard);

    AdminDashboard.$inject = ['$rootScope', '$http'];

    function AdminDashboard ($rootScope, $http) {
        var service = {
            getOpenInvoices: function(demo) {
                return $http.get('api/admin/invoices/open?demo='+demo).then(function (response) {
                    return response.data;
                });
            },
            getPaidInvoices: function(days, demo) {
                return $http.get('api/admin/invoices/paid?days='+days+'&demo='+demo).then(function (response) {
                    return response.data;
                });
            },
            getInvoiceSummary: function (status, days, demo) {
                return $http.get('api/admin/invoices/summary/'+days+'?status='+status+'&demo='+demo).then(function (response) {
                    return response.data;
                });
            },
            getPrintJobSummary: function (days, demo) {
                return $http.get('api/admin/print-jobs/summary/'+days+'?demo'+demo).then(function (response) {
                    return response.data;
                });
            },
            getPrintJobStats: function (demo) {
                return $http.get('api/admin/print-jobs/stats?demo'+demo).then(function (response) {
                    return response.data;
                });
            },
            getPrintJobs: function (demo) {
                return $http.get('api/admin/print-jobs?demo='+demo).then(function (response) {
                    return response.data;
                });
            },
            clearHibernateCache: function (demo) {
                return $http.delete('api/admin/hibernate/cache').then(function (response) {
                    return response.data;
                });
            }
        };

        return service;
    }
})();
