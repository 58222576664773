(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('FAQController', FAQController);

    FAQController.$inject = ['$scope', '$state', 'FAQ', 'FAQSearch'];

    function FAQController ($scope, $state, FAQ, FAQSearch) {
        var vm = this;

        vm.faqs = [];
        vm.categories = {};
        vm.search = search;

        loadAll();

        function loadAll() {
            FAQ.query(processResult);
        }

        function processResult(result) {
            if(result.length > 0) {
                vm.categories = {};
                for (var i = 0; i < result.length; i++) {
                    var c = vm.categories[result[i].category];
                    if(!c) {
                        c = vm.categories[result[i].category] = { count:0, faqs:[] };
                    }
                    c.faqs.push(result[i]);
                    c.count++;
                }
            }
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            FAQSearch.query({query: vm.searchQuery}, processResult);
        }
    }
})();
