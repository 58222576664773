(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .factory('SiteDataSourceSearch', SiteDataSourceSearch);

    SiteDataSourceSearch.$inject = ['$resource'];

    function SiteDataSourceSearch($resource) {
        var resourceUrl =  '/api/_search/site-data-sources/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
