(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('SiteDataSource', SiteDataSource);

    SiteDataSource.$inject = ['$resource', 'DateUtils'];

    function SiteDataSource ($resource, DateUtils) {
        var resourceUrl =  '/api/site-data-sources/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryLinked': { method: 'GET', isArray: true, url: '/api/site-data-sources/linked'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastModified = DateUtils.convertDateTimeFromServer(data.lastModified);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'contactsSourceSubscriptionStatus': {
                method:'POST',
                url: '/api/site-data-sources/:dataSourceId/by-site-user/:siteUserId/subscription-status?print-job=:printJobId',
                params: {printJobId: "@printJobId", siteUserId:"@siteUserId", dataSourceId:"@dataSourceId"}
            }
        });
    }
})();
