(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('UserPricingDetailController', UserPricingDetailController);

    UserPricingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'UserPricing', 'Envelope', 'Country', 'User'];

    function UserPricingDetailController($scope, $rootScope, $stateParams, entity, UserPricing, Envelope, Country, User) {
        var vm = this;

        vm.userPricing = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:userPricingUpdate', function(event, result) {
            vm.userPricing = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
