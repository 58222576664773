(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintStreamDetailController', PrintStreamDetailController);

    PrintStreamDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PrintStream', 'Vendor', 'PrintJob'];

    function PrintStreamDetailController($scope, $rootScope, $stateParams, entity, PrintStream, Vendor, PrintJob) {
        var vm = this;

        vm.printStream = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:printStreamUpdate', function(event, result) {
            vm.printStream = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
