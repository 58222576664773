(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobChargeController', PrintJobChargeController);

    PrintJobChargeController.$inject = ['$scope', '$state', 'PrintJobCharge', 'PrintJobChargeSearch'];

    function PrintJobChargeController ($scope, $state, PrintJobCharge, PrintJobChargeSearch) {
        var vm = this;
        
        vm.printJobCharges = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            PrintJobCharge.query(function(result) {
                vm.printJobCharges = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PrintJobChargeSearch.query({query: vm.searchQuery}, function(result) {
                vm.printJobCharges = result;
            });
        }    }
})();
