(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('InvoiceDetailController', InvoiceDetailController);

    InvoiceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Invoice', 'PrintJobCharge', 'User', 'Country', 'UserProfile', 'Account', 'CostCenter'];

    function InvoiceDetailController($scope, $rootScope, $stateParams, entity, Invoice, PrintJobCharge, User, Country, UserProfile, Account, CostCenter) {
        var vm = this;

        vm.invoice = entity;
        vm.user = Account.get({userId:vm.invoice.userId});
        vm.userProfile = UserProfile.getByUserId({userId:vm.invoice.userId});
        vm.costCenters = CostCenter.withChargeToUser();
        vm.invoiceHistory = [];
        vm.countries = Country.query(function (resp) {
            vm.countryLookup = {};
            resp.forEach(function (c) {
                vm.countryLookup[c.code3] = c;
                vm.countryLookup[c.id] = c;
            });
            return resp;
        });
        vm.allowEditing = vm.invoice.status === 'RAISED' || vm.invoice.status === 'ISSUED';

        function load() {
            vm.invoiceCharges = Invoice.charges({invoiceId:vm.invoice.id});
            vm.invoiceHistory = Invoice.history({invoiceId:vm.invoice.id});
        }

        vm.billingEmail = function() {
            if(vm.userProfile && vm.userProfile.billingEmail) {
                return vm.userProfile.billingEmail;
            }
            return vm.user.email;
        };

        vm.billingPhone = function() {
            if(vm.userProfile && vm.userProfile.billingPhone) {
                return vm.userProfile.billingPhone;
            }
            return vm.userProfile.phone;
        };

        vm.billingContact = function() {
            if(vm.userProfile && vm.userProfile.billingContact) {
                return vm.userProfile.billingContact;
            }
            return (vm.user.firstName || '') + ' ' + (vm.user.lastName || '');
        };

        vm.issueInvoice = function() {
            Invoice.issue({invoiceId: vm.invoice.id}, function (resp) {
                vm.invoice = resp;
                load();
            });
        };

        vm.updateTotals = function() {
            Invoice.updateTotals({invoiceId: vm.invoice.id}, function (resp) {
                vm.invoice = resp;
                load();
            });
        };

        vm.payInvoice = function() {
            Invoice.pay({invoiceId: vm.invoice.id}, function (resp) {
                swal("Submitted.","Your payment request has been received and will be processed shortly.","success");
            });
        };

        vm.assignCostCenter = function() {
            var selected = vm.invoiceCharges.filter(function(e) {
                return e.selected;
            }).map(function(e) {
                return e.id;
            });
            swal({
                title: 'Assign '+selected.length+' charge(s) to Cost Center:',
                input: 'select',
                inputOptions: vm.costCenters.reduce(function(initial,cc) {
                    initial[cc.id] = cc.name;
                    return initial;
                }, {}),
                inputPlaceholder: 'Select cost center',
                showCancelButton: true
            }).then(function(resp){
                if(resp.value) {
                    Invoice.assignChargesToCostCenter({
                        invoiceId: vm.invoice.id,
                        chargeIds: selected,
                        costCenterId: resp.value
                    }).$promise.then(function(resp){
                        vm.invoice = resp;
                        load(); // FIXME use events
                    })
                }
            });
        };

        var unsubscribe = $rootScope.$on('crm2PrintApp:invoiceUpdate', function(event, result) {
            vm.invoice = result;
        });

        load();
        $scope.$on('$destroy', unsubscribe);
    }
})();
