(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('EnvelopeDetailController', EnvelopeDetailController);

    EnvelopeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Envelope', 'Vendor'];

    function EnvelopeDetailController($scope, $rootScope, $stateParams, entity, Envelope, Vendor) {
        var vm = this;

        vm.envelope = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:envelopeUpdate', function(event, result) {
            vm.envelope = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
