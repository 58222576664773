(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .factory('VendorStaffSearch', VendorStaffSearch);

    VendorStaffSearch.$inject = ['$resource'];

    function VendorStaffSearch($resource) {
        var resourceUrl =  'api/_search/vendor-staffs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
