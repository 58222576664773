(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('ReturnToSenderDeleteController',ReturnToSenderDeleteController);

    ReturnToSenderDeleteController.$inject = ['$uibModalInstance', 'entity', 'ReturnToSender'];

    function ReturnToSenderDeleteController($uibModalInstance, entity, ReturnToSender) {
        var vm = this;

        vm.returnToSender = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ReturnToSender.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
