(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('SplashScreenController', SplashScreenController);

    SplashScreenController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', '$sce', 'User', 'userProfile', 'UserProfile'];

    function SplashScreenController ($rootScope, $state, $timeout, Auth, $uibModalInstance, $sce, User, userProfile, UserProfile) {
        var vm = this;

        vm.cancel = cancel;
        vm.doStuff = doStuff;
        vm.showVideo = showVideo;
        vm.closeVideo = closeVideo;
        vm.markGuideAsSeen = markGuideAsSeen;
        vm.credentials = {};
        vm.userProfile = userProfile;
        User.updateLastViewedWelcome();

        $timeout(function (){
            angular.element('#username').focus();
            $('a[title]').tooltip();
        });

        function cancel () {
            $uibModalInstance.dismiss('cancel');
        }

        function doStuff () {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }


        function showVideo(video) {
            vm.videoUrl = $sce.trustAsResourceUrl('https://s3-ap-southeast-2.amazonaws.com/crm2print-static/media/videos/'+video+'.mp4');
            vm.videoTitle = video;
            $('#splash-content').removeClass("flipInX").addClass("flipOutX");
            setTimeout(function(){
                $('#splash-video').show();
            }, 750)
        }

        function closeVideo() {
            $('#splash-video').hide();
            $('#splash-content').removeClass("flipOutX").show();
        }


        function markGuideAsSeen() {
            UserProfile.markGuideAsSeen();
        }

    }
})();
