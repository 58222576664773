(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('manifest-row', {
            parent: 'entity',
            url: '/manifest-row',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_VENDOR'],
                pageTitle: 'crm2PrintApp.manifestRow.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/manifest-row/manifest-rows.html',
                    controller: 'ManifestRowController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('manifestRow');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('manifest-row-detail', {
            parent: 'entity',
            url: '/manifest-row/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_VENDOR'],
                pageTitle: 'crm2PrintApp.manifestRow.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/manifest-row/manifest-row-detail.html',
                    controller: 'ManifestRowDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('manifestRow');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ManifestRow', function($stateParams, ManifestRow) {
                    return ManifestRow.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('manifest-row.new', {
            parent: 'manifest-row',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_VENDOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manifest-row/manifest-row-dialog.html',
                    controller: 'ManifestRowDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                sortPlan: null,
                                letterCount: null,
                                trayCount: null,
                                preSort: null,
                                state: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('manifest-row', null, { reload: true });
                }, function() {
                    $state.go('manifest-row');
                });
            }]
        })
        .state('manifest-row.edit', {
            parent: 'manifest-row',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_VENDOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manifest-row/manifest-row-dialog.html',
                    controller: 'ManifestRowDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ManifestRow', function(ManifestRow) {
                            return ManifestRow.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('manifest-row', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('manifest-row.delete', {
            parent: 'manifest-row',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_VENDOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manifest-row/manifest-row-delete-dialog.html',
                    controller: 'ManifestRowDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ManifestRow', function(ManifestRow) {
                            return ManifestRow.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('manifest-row', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
