(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobEditController', PrintJobEditController);

    PrintJobEditController.$inject = ['$location','$state','$timeout', '$scope', '$stateParams', '$http', 'entity', 'PrintJob', 'PrintJobHistory', 'Site', 'VendorJobTicket', 'Template', 'SiteUser', 'SiteDataSource', 'ListCategory', 'SitePrecincts', 'SiteSubUsers', 'Contact', 'Principal', 'Country', 'UserProfile', 'Vendor', 'Account', 'User', 'Stationery'];

    function PrintJobEditController ($location, $state, $timeout, $scope, $stateParams, $http, entity, PrintJob, PrintJobHistory, Site, VendorJobTicket, Template, SiteUser, SiteDataSource, ListCategory, SitePrecincts, SiteSubUsers, Contact, Principal, Country, UserProfile, Vendor, Account, User, Stationery) {

        /*var stripeKeys = {
            'AUD': $scope.ENV === 'dev' ? 'pk_test_NAwIc50T7FTa46UoeswbkTqb' : 'pk_test_NAwIc50T7FTa46UoeswbkTqb' /!*'pk_live_PS4h0U30PcdeIywnhy7yYrj6'*!/,
            'NZD': $scope.ENV === 'dev' ? 'pk_test_JnSwnyjbCChv9q5Zkno9y8RR' : 'pk_test_JnSwnyjbCChv9q5Zkno9y8RR' /!*pk_live_s0xtBDYQ318BWZllsGkd8Hd5*!/
        }*/

        //FIXME check status only allow NEW and PLACED!!!

        Principal.hasAuthority('ROLE_DEMO_MODE').then(function (demoMode) {
            $scope.demoMode = demoMode;
            if(window.Stripe) {//prevent errors during offline debugging
                Stripe.setPublishableKey(demoMode ? 'pk_test_NAwIc50T7FTa46UoeswbkTqb' : 'pk_live_PS4h0U30PcdeIywnhy7yYrj6');
            }
        });

        //TODO replace with country specify check or availability or optional transactional service?

        var vm = this;
        vm.printJob = entity;
        if($stateParams.isTemplate) {
            vm.printJob.isTemplate = Boolean($stateParams.isTemplate);
        }
        if(vm.printJob.id && (vm.printJob.isTemplate || vm.printJob.status === 'NEW')) {
            PrintJob.charges({printJobId: vm.printJob.id}, function (result, headers) {
                vm.processQuote({'items': result}, headers);
            });
        }
        vm.addressValidationServiceEnabled = false;
        vm.addressValidationRequired = false;

        vm.stationeries = Stationery.query();
        vm.apiUploaded = vm.printJob.contactsSource && vm.printJob.contactsSource.match(/\(via API\)/);
        vm.user = Account.get();
        vm.siteMessages = PrintJob.siteMessages();

        var onSaveSync = function (result) {
            vm.printJob.id = result.id;
            vm.printJob.status = result.status;
            vm.printJob.userId = result.userId;
            vm.printJob.created = result.created;
            vm.isSaving = false;
            return result;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.printJob.id !== null) {
                return PrintJob.update(vm.printJob).$promise;
            } else {
                return PrintJob.save(vm.printJob).$promise;
            }
        };

        $scope.range = function(min, max, step) {
            step = step || 1;
            var input = [];
            for (var i = min; i <= max; i += step) {
                input.push(i);
            }
            return input;
        };

        /*if(!vm.printJob.id) {
            //prevent new printjobs getting created
            vm.save().then(function (resp) {
                onSaveSync(resp);
                var off = $scope.$on('$stateChangeStart', function(e) {
                    e.preventDefault();
                });
                off();
                $location.path("print-job/"+vm.printJob.id+"/edit").replace();
            });
        }*/

        // if(vm.printJob.status != 'NEW' && vm.printJob.status != 'QUOTE') {
        // }
        vm.sites = Site.query();
        vm.siteUsersAll = SiteUser.queryActive();
        vm.countries = Country.query(function (resp) {
            vm.countryLookup = {};
            resp.forEach(function (c) {
                vm.countryLookup[c.code3] = c;
            });
            return resp;
        });
        vm.billingAccount = User.billingAccount();
        vm.userProfile = UserProfile.get(function (p) {
            vm.chargeCurrency = p.currency;
            return p;
        });

        vm.contacts = [];
        vm.contactsCountWithAddress = 0;

        vm._firstTimeLoaded = false;
        $scope.$on("editPrintJob:templateLoaded",function () {
            if(vm._firstTimeLoaded) return;
            vm._firstTimeLoaded = true;

            if(!vm.printJob.parameters) {
                vm.printJob.parameters = vm.template.fields.filter(function (e) {
                    return e.mode == 'CUSTOM';
                }).map(function (e) {
                    return {templateField: e.templateField};
                });
            }

            vm.siteUsers = vm.siteUsersAll.filter(function (su) {
                return su.site.id == vm.template.site.id;
            });
            // select the only user to trigger pre-fetching API data, unless this is a template without id
            if(vm.siteUsers.length == 1 && (!vm.printJob.isTemplate || vm.printJob.id)) {
                vm.printJob.siteUserId = vm.siteUsers[0].id;
            }
            if(vm.printJob.siteUserId) {
                vm.siteUserSelected();
            }
            if(vm.printJob.contactsSource && vm.printJob.contactsCount > 0) {
                // debugger;
                vm.loadContacts();
                if(vm.printJob.contactsValidated) {
                    PrintJob.loadQuote({printJobId: vm.printJob.id}, vm.processQuote, vm.quoteError);
                }
            }
            if(vm.isContactsSourceSubscribable() && vm.printJob.contactsSource) {
                vm.getContactsSourceSubscriptionStatus();
                vm.getQuote();
            }
        });

        $scope.$on("editPrintJob:contactsChanged", function (event) {
            vm.getQuote();
        });

        vm.contactSelected = function () {
            $scope.$emit("editPrintJob:contactsChanged");
            vm.contactSelectionDirty = true;
        };

        Principal.identity().then(function(account) {
            vm.account = account;
            vm.templates = Template.query({templateId:vm.printJob.templateId}, function(resp){
                return resp.forEach(function(t){
                    //in order to be able to create template option groups
                    t.category = t.creator.login == account.login ? "My Templates" : "Shared Templates";
                })
            });
        });

        vm.hasExistingSource = function() {
            return !!vm.billingAccount.sources && vm.billingAccount.sources.totalCount >= 1;
        };
        vm.getExistingSource = function() {
            return vm.hasExistingSource() ? vm.billingAccount.sources.data[0] : null;
        };

        $scope.dropzoneConfig = {
            'options': { // passed into the Dropzone constructor
                'url': '/api/assets/templates',
                dictDefaultMessage: 'Upload CSV or MS Excel file.<div class="file-formats"><i class="fa fa-file-excel-o"></i></div>',
                maxFiles: 1,
                parallelUploads: 1,
                maxFilesize : 10,
                uploadMultiple: false,
                acceptedFiles: '.csv,.xlsx,.xls'
            },
            'eventHandlers': {
                'sending': function (file, xhr, formData) {
                    // console.log("sending: "+file);
                },
                'success': function (file, response) {
                    // console.log(response);
                    $scope.dropzoneConfig.dropzone.removeFile(file);
                    vm.doc = response;
                    vm.printJob.contactsSource = vm.doc.originalFilename;
                    vm.loadContacts();
                    $scope.$digest();
                }
            }
        };

        vm.generateUploadedFileThumbnailURL = function(key) {
            return "/api/assets/templates/"+key+"/preview?scaleFactor=0.1&convertOnly=true";
        };

        //http://stackoverflow.com/questions/17582713/programatically-add-existing-file-to-dropzone
        $scope.dropzoneConfigInserts = {
            'options': { // passed into the Dropzone constructor
                'url': '/api/assets/templates?convert-to=pdf',
                dictDefaultMessage: 'Insert Additional Pages <br/><i class="fa fa-file-pdf-o"></i><i class="fa fa-file-word-o"></i>', //
                maxFiles: 2,
                parallelUploads: 1,
                maxFilesize : 3,
                uploadMultiple: true,
                addRemoveLinks: true,
                acceptedFiles: '.pdf,.doc,.docx,.rtf'
            },
            'eventHandlers': {
                'sending': function (file, xhr, formData) {
                    // console.log("sending: "+file);
                },
                'success': function (file, response) {
                    if(vm.printJob.pageCount + response.pageCount > 10) {
                        swal("Maximum Page Count Exceeded!","An envelope cannot hold more than 5 sheets (10 pages). Your last upload has been rejected.","error")
                        $scope.dropzoneConfigInserts.dropzone.removeFile(file);
                        return;
                    }
                    if(!vm.printJob.inserts) vm.printJob.inserts = [];
                    vm.printJob.inserts.push(response);
                    file.doc = response;
                    updatePageCount();
                    $scope.$digest();
                    $scope.dropzoneConfigInserts.dropzone.createThumbnailFromUrl(file, vm.generateUploadedFileThumbnailURL(response.key));
                    if(file.name != response.originalFilename) {
                        swal("Automatic conversion to PDF","Your uploaded document "+file.name+" has automatically been converted to PDF.","success");
                    }
                },
                'error': function (file, errorMessage, xhr) {
                    swal("Failed to upload", xhr.getResponseHeader("x-crm2printapp-error") || errorMessage, "error");
                    $scope.dropzoneConfigInserts.dropzone.removeFile(file);
                },
                'removedfile': function (file) {
                    var idx = vm.printJob.inserts.indexOf(file.doc);
                    if(idx >=0)
                        vm.printJob.inserts.splice(idx,1);
                    updatePageCount();
                    $scope.$digest();
                }
            }
        };

        $timeout(function() {
            if(vm.printJob.inserts) {
                vm.printJob.inserts.forEach(function(insert) {
                    var mockFile = {name: insert.originalFilename, size: insert.size, doc: insert};
                    var dz = $scope.dropzoneConfigInserts.dropzone;
                    dz.options.addedfile.call(dz, mockFile);
                    dz.options.thumbnail.call(dz, mockFile, vm.generateUploadedFileThumbnailURL(insert.key));
                });
            }
        });

        var updatePageCount = function() {
            var count = vm.template ? vm.template.numberOfPages : 0;
            if(vm.printJob.inserts) {
                vm.printJob.inserts.forEach(function (d) {
                    count += d.pageCount;
                });
            }
            vm.printJob.pageCount = count;
            vm.clearQuote();
        };

        vm.clearContacts = function(clearValidation) {
            if(!vm.contactsLoading) {
                delete vm.doc;
            }
            //delete vm.printJob.contactsSource;
            vm.contactsCountWithAddress = 0;
            vm.contactsCountRaw = 0;
            vm.metaLookup = {};
            vm.contacts = [];
            vm.contactLookup = {};
            vm.noContacts = false;
            vm.contactsLoading = false;
            if(clearValidation) {
                delete vm.printJob.contactsValidated;
            }
            if(vm.dtInstance) {
                vm.dtInstance.fnDestroy();
            }
        };

        $scope.handleStripe = function(status, response){
            if(response.error) {
                // there was an error. Fix it.
                swal("Payment Type Verification Error", response.error, "error");
            } else {
                // got stripe token, now checkout as per ususal
                vm.stripeToken = response.id
                vm.checkoutWithExisting()
            }
        };

        vm.checkoutError = function(e) {
            vm.isSaving = false;
            console.log(e);
            swal("Checkout Error", e.data && e.data.error || "Please try again.","error");
        };

        vm.checkoutWithExisting = function() {
            if(vm.isSaving) return;
            vm.save()
                .then(vm.saveContacts)
                .then(function(){
                    return PrintJob.checkout({
                        id: vm.printJob.id,
                        stripeToken: vm.stripeToken
                    }).$promise
                })
                .then(onSaveSuccess, vm.checkoutError)
        };

        vm.saveAsPrintJobTemplate = function(subscribe) {
            vm.save()
                .then(function(){
                    return PrintJob.saveAsPrintJobTemplate({
                        id: vm.printJob.id,
                        stripeToken: vm.stripeToken,
                        subscribe: subscribe
                    }).$promise
                })
                .then(onSaveSuccess, vm.checkoutError)
        };

        vm.unsubscribeFromContactsSource = function () {
            return PrintJob.unsubscribeFromContactsSource(
                angular.extend({}, vm.printJob.contactsFilter, {
                    'printJobId': vm.printJob.id
                })
            , function(resp) {
                vm.listCategories.filter(function (category) {
                    return category.categoryId == vm.printJob.contactsFilter.category;
                }).forEach(function (category) {
                    category.subscriptionStatus = 'Subscribable';
                });
                vm.subscriptionStatus = 'Subscribable';
            })
        };

        vm.updateCountsByCountry = function() {
            vm.countsByCountry = {"NZL":0,"AUS":0,"Other":0};//FIXME generate by supported countries
            vm.contactsCountGood = 0;
            vm.contacts.forEach(function(c){
                if(c.$valid) {
                    vm.countsByCountry[c.$countrycode]++;
                    vm.contactsCountGood++;
                }
            });
        };

        vm.countriesWithContacts = function() {
            return vm.countries.filter(function(country){
                return vm.countsByCountry[country.code3] > 0;
            });
        };

        vm.calculateTotal = function() {
            if(!vm.quote) return 0;
            return vm.printJob.priceGross;
        };


        vm.clearQuote = function() {
            delete vm.quote;
            vm.quoteByCountry = {};
            vm.printJob.priceGross = 0;
            vm.printJob.letterCount = 0;
            vm.printJob.priceGross = 0;
        };

        vm.quoteError = function(resp) {
            vm.isQuoting = false;
        };

        vm.processQuote = function(resp, headers) {
            // debugger;
            if(headers("Pickup-ID")) {
                $timeout(function(){
                    // $http.get(headers("Pickup-Location"),{headers:{"Authorization":"d"}}).then(vm.processQuote, vm.processQuote);
                    PrintJob.pickupQuote({id:headers("Pickup-ID")}, vm.processQuote, vm.quoteError);
                }, 3000);
            } else {
                vm.quote = resp.items;
                vm.quoteByCountry = {};
                vm.printJob.priceGross = 0;
                vm.printJob.letterCount = 0;
                vm.quote.forEach(function (row) {
                    vm.quoteByCountry[row.country] = row;
                    vm.printJob.priceGross += row.chargeAmount;
                    checkVendorAvailabilityForCountry(row);
                });
                vm.isQuoting = false;
            }
        };

        vm.getQuote = function() {
            vm.isQuoting = true;
            vm.clearQuote();
            if(vm.printJob.isTemplate) {
                vm.save().then(function(printJob) {
                    onSaveSync(printJob);
                    PrintJob.quote({printJobId: vm.printJob.id}, vm.processQuote, vm.quoteError);
                });
            } else {
                if (vm.addressValidationRequired && !vm.printJob.contactsValidated) return;
                vm.updateCountsByCountry();
                if (!vm.countsByCountry) return 0;
                vm.save().then(function (printJob) {
                    onSaveSync(printJob);
                    PrintJob.quote({
                        printJobId: vm.printJob.id,
                        contactsMeta: vm.createContactsMeta(),
                        file: vm.doc
                    }, vm.processQuote, vm.quoteError);
                });
            }
        };

        var checkVendorAvailabilityForCountry = function(row) {
            Vendor.checkVendorAvailabilityForPrintJob({countryCode3:row.country,letterCount:row.quantity, printJobId:vm.printJob.id},function (resp) {
                if(resp.length == 0) {
                    row.vendor = "NONE";
                } else {
                    vm.printJob.letterCount = 0;
                    resp.forEach(function(limitation){
                        if(limitation == "") {
                            row.vendor = "OK";
                            vm.printJob.letterCount += row.quantity;
                            delete row.vendorLimitation;
                        } else if(row.vendor != "OK") {
                            row.vendor = "LIMITED";
                            row.vendorLimitation = limitation;
                        }
                    })
                }
                updateVendorAvailabilitySummary();
            });
        };

        var updateVendorAvailabilitySummary = function () {
            vm.vendorsAvailable = true;
            if(vm.quote) {
                vm.quote.forEach(function (row) {
                    vm.vendorsAvailable &= row.vendor == "OK"
                });
            }
        };

        $timeout(function (){

            if(vm.printJob.templateId) {
                vm.loadTemplate();
            }

            jQuery('.print-job-wizard').bootstrapWizard({
                'tabClass': '',
                'firstSelector': '.wizard-first',
                'previousSelector': '.wizard-prev',
                'nextSelector': '.wizard-next',
                'lastSelector': '.wizard-last',
                'onTabShow': function(tab, navigation, index) {
                    var total      = navigation.find('li').length;
                    var current    = index + 1;
                    var percent    = (current/total) * 100;

                    // Get vital wizard elements
                    var wizard     = navigation.parents('.block');
                    var progress   = wizard.find('.wizard-progress > .progress-bar');
                    var btnPrev    = wizard.find('.wizard-prev');
                    var btnNext    = wizard.find('.wizard-next');
                    var btnFinish  = wizard.find('.wizard-finish');

                    // Update progress bar if there is one
                    if (progress) {
                        progress.css({ width: percent + '%' });
                    }

                    // If it's the last tab then hide the last button and show the finish instead
                    if(current >= total) {
                        btnNext.hide();
                        btnFinish.show();
                    } else {
                        btnNext.show();
                        btnFinish.hide();
                    }
                    if(current > 1) {
                        vm.save().then(onSaveSync);
                        btnPrev.show();
                    } else {
                        btnPrev.hide();
                    }
                },
                onTabClick: function(tab, navigation, index) {
                    return false;
                }
            });

            angular.element('.form-group:eq(1)>input').focus();

            // Init Card, for more info and examples you can check out https://github.com/jessepollak/card
            jQuery('.js-card-form').card({
                container: '.js-card-container',
                formSelectors: {
                    numberInput: '#cc_number',
                    expiryInput: '#cc_expiry',
                    cvcInput: '#cc_cvc',
                    nameInput: '#cc_name'
                }
            });
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('crm2PrintApp:printJobUpdate', result);
            vm.isSaving = false;
            if(result.status == 'MERGE_READY') {
                $timeout(function () {
                    $state.go("print-jobs",{status:"in-progress"});
                });
                swal({
                    title: "Success!!!",
                    html: "Your Order has been received." +
                        "<br/>Your letters will be printed &amp; posted by the Mail house within 48 hours.<br/> Your reference for this print job is #"+result.id,
                    type: "success",
                    closeOnConfirm: true
                }).then(function(isConfirm){
                    $state.go('print-job-detail', {id: result.id});
                });
            }
            if(result.status == 'SAVED') {
                $state.go('print-jobs', {status: 'saved'});
            } else if(result.status == 'SUBSCRIBED') {
                swal({
                    title: "Subscribed!!!",
                    html: "Once " + vm.template.site.name+ " has discovered new leads, it will submit a new print job." +
                        "<br/>The letters will then be printed &amp; posted by the Mail house within 48 hours.",
                    type: "success",
                    closeOnConfirm: true
                }).then(function(isConfirm){
                    $state.go('print-jobs', {status: 'saved'});
                })
            }
        };

        vm.fieldName = function(field) {
            if(field.mode == 'CUSTOM') {
                return field.templateField;
            } else {
                return field.mappedField;
            }
        };

        vm.fieldValue = function(field, contact) {
            if(field.mode == 'CUSTOM') {
                //why is this templateField?
                return contact.custom[field.templateField] || contact.custom[field.mappedField];
            } else {
                return contact[field.mappedField];
            }
        };


        vm.visibleFields = function () {
            if(!vm.template) return [];
            else if ($scope.visibleFields) return $scope.visibleFields;
            else {
                return $scope.visibleFields = vm.template.fields.filter(function (f) {
                    return f.display && !f.generated;
                });
            }
        };
        vm.generatedFields = function () {
            if(!vm.template) return [];
            else if ($scope.generatedFields) return $scope.generatedFields;
            else {
                return $scope.generatedFields = vm.template.fields.filter(function (f) {
                    return f.display && f.generated;
                });
            }
        };

        vm.saveContacts = function(printJob, jsonOnly) {
            if (!vm.printJob.contactsFilter)
                vm.printJob.contactsFilter = {};
            return PrintJob.saveContacts({
                printJobId: printJob.id,
                contactsMeta: vm.createContactsMeta(),
                file: vm.doc,
                siteUserId: vm.printJob.siteUserId,
                subUserId: vm.printJob.contactsFilter.user || "",
                precinctId: vm.printJob.contactsFilter.precinct || "",
                categoryId: vm.printJob.contactsFilter.category || "",
                listName: vm.printJob.contactsSource,
                jsonOnly: jsonOnly
            }).$promise;
        };

        vm.clear = function() {
            $state.go('print-jobs',{status:"draft"});
        };
        vm.saveAndClose = function() {
            vm.save().then(function(){
                vm.saveContacts(vm.printJob,true).then(function(){
                    if(vm.printJob.contactsValidated && vm.contactsCountWithAddress > 0 && vm.userProfile.approverEmail) {
                        swal({
                            title: "Your print job is nearly complete.",
                            text: "Would you like to send a notification to your Print Job approver?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, send notification to "+vm.userProfile.approverEmail,
                            cancelButtonText: "No, just save.",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        }).then(function(result){
                            if (result.value) {
                                PrintJob.sendApproverNotification({printJobId:vm.printJob.id});
                            }
                        });
                    }
                    vm.clear();
                });
            })
        };

        vm.hasSavedContacts = function() {
            return vm.printJob.contactsSource && vm.printJob.contactsCount > 0;
        };

        vm.clearSavedContacts = function () {
            delete vm.printJob.contactsSource;
            delete vm.printJob.contactsCount;
            PrintJob.deleteContacts({printJobId:vm.printJob.id});
            vm.clearContacts(true);
        };

        vm.siteUserSelected = function() {
            if(vm.printJob.siteUserId) {
                vm.siteUsers.forEach(function (su) {
                    if(su.id == vm.printJob.siteUserId) {
                        vm.siteUser = su;
                    }
                })
            }
            if(vm.template.dataSource.type == 'API') {
                vm.loadListCategories();
                vm.loadSitePrecincts();
                vm.loadSiteSubUsers();
            }
        };

        vm.loadListCategories = function() {
            if(!vm.printJob.siteUserId) {
                delete vm.listCategories;
                return;
            }
            if(!vm.printJob.id) {
                //this function executes on first load ad may not have a printJob id yet
                $timeout(vm.loadListCategories,1000);
                return;
            }
            vm.categoriesLoading = true;
            vm.listCategories = ListCategory.query({
                siteUserId: vm.printJob.siteUserId,
                dataSourceId: vm.template.dataSource.id,
                printJobId: vm.printJob.id
            }, function(resp){
                vm.categoriesLoading = false;
                return resp;
            }, function(resp){
                debugger;
                swal("Error","Failed to load categories","error");
            });
        };

        vm.loadSitePrecincts = function() {
            if(!vm.printJob.siteUserId) {
                delete vm.sitePrecincts;
                return;
            }
            vm.sitePrecinctsLoading = true;
            SitePrecincts.query({siteUserId: vm.printJob.siteUserId, dataSourceId: vm.template.dataSource.id},function(resp){
                vm.sitePrecinctsLoading = false;
                if(resp.length > 0) {
                    resp.forEach(function(prec){
                        if(!vm.sitePrecinct && vm.printJob.contactsFilter && vm.printJob.contactsFilter.precinct ==  prec.precinctId) {
                            vm.sitePrecinct = prec;
                        }
                    });
                    resp.splice(0, 0, {
                        name: "any"
                    });
                    vm.sitePrecincts = resp;
                }
            });
        };

        vm.loadSiteSubUsers = function() {
            if(!vm.printJob.siteUserId) {
                delete vm.siteSubUsers;
                return;
            }
            vm.siteSubUsersLoading = true;
            SiteSubUsers.query({siteUserId: vm.printJob.siteUserId, dataSourceId: vm.template.dataSource.id},function(resp){
                if(resp.length > 0) {
                    resp.forEach(function (su) {
                        su.name = ((su.firstName || "") + " " + (su.lastName || "")).trim();
                        if(!vm.siteSubUser && vm.printJob.contactsFilter && vm.printJob.contactsFilter.user ==  su.userId) {
                            vm.siteSubUser = su;
                        }
                    })
                    vm.siteSubUsersLoading = false;
                    resp.splice(0, 0, {
                        name: "any"
                    });
                    vm.siteSubUsers = resp;
                }
            });
        };

        var templateLoaded = function(resp) {
            if(!resp) {
                delete vm.parameters;
            }
            vm.template = resp;
            updatePageCount();
            delete $scope.visibleFields;
            $scope.$emit("editPrintJob:templateLoaded");
        };

        vm.loadTemplate = function() {
            if(vm.printJob.templateId) {
                Template.get({id: vm.printJob.templateId}, templateLoaded);
            } else {
                templateLoaded(null);
            }
        };

        vm.saveDraftAndPreview = function() {
            vm.save().then(function(printJob) {
                onSaveSync(printJob);//make sure sure we have an id
                PrintJob.generatePreview({
                    printJobId: printJob.id,
                    contactsMeta: vm.createContactsMeta(),
                    file: vm.doc
                }, function (resp) {
                    vm.isSaving = false;
                    vm.previewUrl = resp.url;
                    swal({
                        title: "Preview generated!!",
                        html: "Please <a href='" + vm.previewUrl + "' target='_blank'><strong>click here</strong></a> to download your preview PDF.",
                        type: "success"
                    });
                }, function() {
                    swal("Error", "Failed to generate preview PDF. Please try again.", "error");
                });
            })
        };

        vm.saveDraftAndMailMerge = function() {
            vm.save().then(function(printJob) {
                onSaveSync(printJob);//make sure sure we have an id
                PrintJob.generateMailMerge({
                    printJobId: printJob.id,
                    contactsMeta: vm.createContactsMeta(),
                    file: vm.doc
                }, function () {
                    vm.isSaving = false;
                    vm.downloadUrl = "/api/assets/print-jobs/" + vm.printJob.id + "/mailmerge.pdf";
                    swal({
                        title: "Mail-Merged PDF generated!!",
                        html: "Please <a href='" + vm.downloadUrl + "' target='_blank'><strong>click here</strong></a> to download your PDF.",
                        type: "success"
                    });
                }, function() {
                    swal("Error", "Failed to generate preview PDF. Please try again.", "error");
                });
            })
        };

        vm.onCategorySelect = function($item, $model) {
            vm.printJob.contactsSource = $item.name;

            if(vm.isContactsSourceSubscribable()) {
                vm.getContactsSourceSubscriptionStatus();
                vm.getQuote();
            }
        };

        vm.getContactsSourceSubscriptionStatus = function() {
            SiteDataSource.contactsSourceSubscriptionStatus(
                angular.extend({}, vm.printJob.contactsFilter, {
                    'printJobId': vm.printJob.id,
                    'siteUserId': vm.printJob.siteUserId,
                    'dataSourceId': vm.template.dataSource.id
                }
            ), function(resp){
                vm.subscriptionStatus = resp.status;
            }, function(resp){
                swal({
                    title: "Failed to get subscription status!",
                    text: "Please check your credentials for "+vm.template.site.name+".",
                    type: "error"
                });
            });
        };

        vm.isContactsSourceSubscribable = function() {
            if(!vm.template || !vm.template.dataSource) return;
            return vm.template.dataSource.type == 'API' && (vm.template.site.name == 'AgentInsight' || vm.template.site.name == 'NEXU');
        };

        vm.updateContactsSource = function() {
            if(vm.template.dataSource.type == 'API') {
                vm.printJob.contactsSource = vm.listCategory ? vm.listCategory.name : "";
            } else if(vm.template.dataSource.type == 'CSV') {
                vm.printJob.contactsSource = vm.doc.originalFilename;
            }
        };

        vm.loadContacts = function() {
            vm.contactsLoading = true;
            if(vm.hasSavedContacts()) {
                vm.clearContacts(false);
                PrintJob.loadContacts({
                    printJobId: vm.printJob.id
                }, vm.processContacts, vm.noContactsFound);
            } else if(vm.template.dataSource.type == 'API') {
                vm.clearContacts(true);
                //FIXME
                vm.printJob.contactsSource = vm.listCategory ? vm.listCategory.name : "";
                Contact.query({
                    siteUserId: vm.printJob.siteUserId,
                    subUserId: vm.printJob.contactsFilter.user || "",
                    precinctId: vm.printJob.contactsFilter.precinct || "",
                    categoryId: vm.printJob.contactsFilter.category || "",
                    listName: vm.printJob.contactsSource,
                    printJobId: vm.printJob.id,
                    dataSourceId: vm.template.dataSource.id
                }, vm.processContacts, vm.noContactsFound);

            } else if(vm.template.dataSource.type == 'CSV') {
                vm.clearContacts(true);
                vm.printJob.contactsSource = vm.doc.originalFilename;
                Contact.readFromCSV({
                    siteUserId: vm.printJob.siteUserId,
                    docId: vm.doc.key,
                    listName: vm.printJob.contactsSource,
                    printJobId: vm.printJob.id,
                    templateId: vm.printJob.templateId,
                    dataSourceId: vm.template.dataSource.id
                }, vm.processContacts, vm.noContactsFound);
            }
        };

        vm.noContactsFound = function(resp){
            vm.clearContacts(false);
            vm.noContacts = true;
        };

        vm.processContacts = function(resp){
            vm.doc = resp.file;
            vm.contactsCountRaw = resp.count;
            var generatedFields = vm.generatedFields();
            var addressFields = vm.visibleFields().filter(function(f){
                return f.templateField == "c2p_streetaddress_1" || f.templateField == "c2p_city";
            });
            //should we just check if printJob,contactSource == API?
            var generatedFieldsOnly = generatedFields.length > 0 && addressFields.length == 0;

            var field1, field2, field1Mode, field2Mode;
            if(!generatedFieldsOnly) {
                // field1 = addressFields[0].mappedField.toLowerCase();
                field1 = addressFields[0].mappedField;
                field1Mode = addressFields[0].mode;
                // field2 = addressFields[1].mappedField.toLowerCase();
                field2 = addressFields[1].mappedField;
                field2Mode = addressFields[1].mode;
            }
            vm.contactLookup = {};
            vm.contactsCountWithAddress = 0;
            vm.contacts = resp.contacts.map(function(c){
                if(!generatedFieldsOnly) {
                    var mappedFieldVal1 = field1Mode === 'CUSTOM' ? c.custom[field1] : c[field1];
                    var mappedFieldVal2 = field2Mode === 'CUSTOM' ? c.custom[field2] : c[field2];
                    if (typeof c.$valid == 'undefined') {
                        c.$valid = !!mappedFieldVal1 && mappedFieldVal1.trim().length > 0
                            && !!mappedFieldVal2 && mappedFieldVal2.trim().length > 0
                            && !!vm.countryLookup[c.$countrycode];
                    }
                } else {
                    //assumed to be pre-validated during API upload
                    // c.$valid = c.valid && !!vm.countryLookup[c.$countrycode];
                }

                // if(c.$valid !== undefined)
                if(c.$valid)
                    vm.contactsCountWithAddress++;

                //create lookups by $id for contact and contact meta
                vm.contactLookup[c.$id] = c;
                return c;
            });

            vm.createContactsMeta();
            if(generatedFieldsOnly) {
                vm.syncValidation(resp.contacts);
            }
            vm.updateCountsByCountry();
            $scope.$emit("editPrintJob:contactsChanged");
            $timeout(function () {
                //DOM has finished rendering
                vm.dtInstance = $('table.contacts').dataTable({
                    pageLength: 10,
                    "scrollX": true,
                    "deferRender": true,
                    "language": {
                        "emptyTable": "No contacts",
                        "zeroRecords": "No records to display"
                    }
                });
                //ensure the contacts tabs is active
                if($('.print-job-wizard').bootstrapWizard('currentIndex') != 1) {
                    $('.print-job-wizard').bootstrapWizard('show', 1);
                    $timeout(function () {
                        $('table.contacts').DataTable().draw(false);
                    }, 200);
                }
            });
        };

        vm.createContactsMeta = function() {
            vm.metaLookup = {};
            return vm.contacts.map(function(c){
                return vm.metaLookup[c.$id] = {valid: c.$valid, id: c.$id};
            });
        };

        //this method is copied from the response of validateContacts however adapted for pre-validated API-uploaded contacts
        //FIXME remove duplicate code
        vm.syncValidation = function (resp) {
            // vm.createContactsMeta();
            resp.forEach(function(rec){
                var contact = vm.contactLookup[rec.id];
                if(contact) {
                    contact.$valid = rec.valid;
                    contact.$flag = rec.flag;
                    var flag = vm.flags[rec.flag];
                    if(flag) {
                        contact.$flagAlt = flag.meaning;
                        if(flag.example) {
                            contact.$flagAlt += ", "+flag.example
                        }
                    }
                }
                var meta = vm.metaLookup[rec.id];
                if(meta) {
                    meta.valid = rec.valid;
                } else {
                    debugger;
                }
            });
            vm.getQuote();
        };

        vm.validateContacts = function() {
            PrintJob.validateContacts({
                printJobId: vm.printJob.id,
                contactsMeta: vm.createContactsMeta(),
                file: vm.doc
            }, function (resp) {
                if(resp.length > 0) {
                    vm.printJob.contactsValidated = new Date();
                }
                //FIXME is this right, or do we only accept addresses validated by address washing?
                vm.printJob.letterCount = resp.length;

                resp.forEach(function(rec){
                    var contact = vm.contactLookup[rec.id];
                    if(contact) {
                        contact.$valid = rec.valid;
                        contact.$flag = rec.flag;
                        var flag = vm.flags[rec.flag];
                        if(flag) {
                            contact.$flagAlt = flag.meaning;
                            if(flag.example) {
                                contact.$flagAlt += ", "+flag.example
                            }
                        }
                    }
                    var meta = vm.metaLookup[rec.id];
                    if(meta) {
                        meta.valid = rec.valid;
                    } else {
                        debugger;
                    }
                });
                vm.updateCountsByCountry();
                if(vm.contactsCountGood < vm.contactsCountWithAddress) {
                    swal("Address Validation Complete","The system has identified "+(vm.contactsCountWithAddress - vm.contactsCountGood) +" bad addresses and " +
                        "has automatically removed them from the list. Please review the selection before proceeding.","warning");
                }
                vm.getQuote();
            });
        };

        vm.requireContactsFilter = function() {
            return vm.template && vm.template.dataSource && vm.template.dataSource.type == 'API' && !vm.sitePrecinct && !vm.siteSubUser && !vm.printJob.contactsFilter.category && !vm.contactsCountWithAddress;
        };

        vm.contactValidationAvailable = function() {
            return vm.addressValidationServiceEnabled && !vm.printJob.isTemplate;
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.created = false;
        vm.datePickerOpenStatus.lastModified = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        // DataTables Bootstrap integration
        var bsDataTables = function() {
            var $DataTable = jQuery.fn.dataTable;

            // Set the defaults for DataTables init
            jQuery.extend( true, $DataTable.defaults, {
                dom:
                "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-6'i><'col-sm-6'p>>",
                renderer: 'bootstrap',
                oLanguage: {
                    sLengthMenu: "_MENU_",
                    sInfo: "Showing <strong>_START_</strong>-<strong>_END_</strong> of <strong>_TOTAL_</strong>",
                    oPaginate: {
                        sPrevious: '<i class="fa fa-angle-left"></i>',
                        sNext: '<i class="fa fa-angle-right"></i>'
                    }
                }
            });

            // Default class modification
            jQuery.extend($DataTable.ext.classes, {
                sWrapper: "dataTables_wrapper form-inline dt-bootstrap",
                sFilterInput: "form-control",
                sLengthSelect: "form-control"
            });

            // Bootstrap paging button renderer
            $DataTable.ext.renderer.pageButton.bootstrap = function (settings, host, idx, buttons, page, pages) {
                var api     = new $DataTable.Api(settings);
                var classes = settings.oClasses;
                var lang    = settings.oLanguage.oPaginate;
                var btnDisplay, btnClass;

                var attach = function (container, buttons) {
                    var i, ien, node, button;
                    var clickHandler = function (e) {
                        e.preventDefault();
                        if (!jQuery(e.currentTarget).hasClass('disabled')) {
                            api.page(e.data.action).draw(false);
                        }
                    };

                    for (i = 0, ien = buttons.length; i < ien; i++) {
                        button = buttons[i];

                        if (jQuery.isArray(button)) {
                            attach(container, button);
                        }
                        else {
                            btnDisplay = '';
                            btnClass = '';

                            switch (button) {
                                case 'ellipsis':
                                    btnDisplay = '&hellip;';
                                    btnClass = 'disabled';
                                    break;

                                case 'first':
                                    btnDisplay = lang.sFirst;
                                    btnClass = button + (page > 0 ? '' : ' disabled');
                                    break;

                                case 'previous':
                                    btnDisplay = lang.sPrevious;
                                    btnClass = button + (page > 0 ? '' : ' disabled');
                                    break;

                                case 'next':
                                    btnDisplay = lang.sNext;
                                    btnClass = button + (page < pages - 1 ? '' : ' disabled');
                                    break;

                                case 'last':
                                    btnDisplay = lang.sLast;
                                    btnClass = button + (page < pages - 1 ? '' : ' disabled');
                                    break;

                                default:
                                    btnDisplay = button + 1;
                                    btnClass = page === button ?
                                        'active' : '';
                                    break;
                            }

                            if (btnDisplay) {
                                node = jQuery('<li>', {
                                    'class': classes.sPageButton + ' ' + btnClass,
                                    'aria-controls': settings.sTableId,
                                    'tabindex': settings.iTabIndex,
                                    'id': idx === 0 && typeof button === 'string' ?
                                    settings.sTableId + '_' + button :
                                        null
                                    })
                                    .append(jQuery('<a>', {
                                            'href': '#'
                                        })
                                            .html(btnDisplay)
                                    )
                                    .appendTo(container);

                                settings.oApi._fnBindAction(
                                    node, {action: button}, clickHandler
                                );
                            }
                        }
                    }
                };

                attach(
                    jQuery(host).empty().html('<ul class="pagination"/>').children('ul'),
                    buttons
                );
            };

            // TableTools Bootstrap compatibility - Required TableTools 2.1+
            if ($DataTable.TableTools) {
                // Set the classes that TableTools uses to something suitable for Bootstrap
                jQuery.extend(true, $DataTable.TableTools.classes, {
                    "container": "DTTT btn-group",
                    "buttons": {
                        "normal": "btn btn-default",
                        "disabled": "disabled"
                    },
                    "collection": {
                        "container": "DTTT_dropdown dropdown-menu",
                        "buttons": {
                            "normal": "",
                            "disabled": "disabled"
                        }
                    },
                    "print": {
                        "info": "DTTT_print_info"
                    },
                    "select": {
                        "row": "active"
                    }
                });

                // Have the collection use a bootstrap compatible drop down
                jQuery.extend(true, $DataTable.TableTools.DEFAULTS.oTags, {
                    "collection": {
                        "container": "ul",
                        "button": "li",
                        "liner": "a"
                    }
                });
            }
        };
        bsDataTables();
        vm.updateCountsByCountry(); //init to 0

        //FIXME
        vm.flags = {
            "A": {
                "meaning": "Insufficient locality info",
                "example": "e.g. 29 George St, SYDNEY"
            },
            "B": {
                "meaning": "Cannot match locality info",
                "example": "e.g. 29 George St, SYDNEY VIC"
            },
            "E": {
                "meaning": "PAF validation not performed, an error occurred preventing accessing the PAF files."
            },
            "F": {
                "meaning": "No street of that name in this locality",
                "example": "e.g. 2 Mad St, SYDNEY NSW  2000"
            },
            "G": {
                "meaning": "No such postal type in that locality",
                "example": "e.g. Private Bag 1, SYDNEY NSW 2001 (There are only GPO Boxes and Locked Bags in 2001)"
            },
            "I": {
                "meaning": "No street name in address",
                "example": "e.g. Melbourne, VIC 3000"
            },
            "J": {
                "meaning": "Postal prefix or suffix doesn't match",
                "example": "e.g. GPO BOX 1B, MELBOURNE  VIC  3001 (should be GPO Box 1A)"
            },
            "K": {
                "meaning": "Number range invalid (first number greater than second)",
                "example": "e.g. 6-2 Mill St, Perth WA 6000"
            },
            "L": {
                "meaning": "No house number in that street",
                "example": "e.g. 10000 CAMPBELL  ST, HOBART  TAS  7000"
            },
            "M": {
                "meaning": "No such postal number",
                "example": "e.g. GPO BOX 999999, BRISBANE  QLD  4001"
            },
            "N": {
                "meaning": "No such lot number",
                "example": "e.g. Lot 4 CARRINGTON  ST, ADELAIDE  SA  5000"
            },
            "O": {
                "meaning": "No such postal address with no number",
                "example": "e.g. CMB, DARWIN  NT  0801 (CMB in Darwin have numbers)"
            },
            "P": {
                "meaning": "Missing house number in address",
                "example": "e.g. CAVENAGH  ST, DARWIN  NT  0800"
            },
            "Q": {
                "meaning": "Ambiguous street (or NZ Lobby)",
                "example": "e.g. 2 MACQUARIE AVE, SYDNEY  NSW  2000 (there is Macquarie Street and Place, but no Avenue)"
            },
            "R": {
                "meaning": "Ambiguous house number or within range",
                "example": "e.g. 13-17 CONSTITUTION  AVE, CANBERRA  ACT  2600 (there is a 13-15 and a 17) or e.g. 341 BRISBANE  ST, BEAUDESERT  QLD  4285 (the number is 339-347)"
            },
            "S": {
                "meaning": "Matches to more than one address",
                "example": "e.g. 222 Pacific Highway, 2065 NSW (this address appears in several localities Crows Nest, St Leonards and Greenwich) "
            },
            "U": {
                "meaning": "Too many differences. The amendedFlag field will list the differences.",
                "example": "e.g. 29 George, SYDNEY NSW (postcode missing, and street type missing)"
            },
            "V": {
                "meaning": "G/PO Box address with incorrect locality, or changed from GPO to PO Box and postcode incorrect.",
                "example": "e.g. GPO BOX 1, NSW 2001 (missing the locality SYDNEY) or e.g. GPO BOX 1, NORTH SYDNEY  NSW  2060  (there is a PO Box 1, North Sydney NSW 2059)"
            },
            "X": {
                "meaning": "Missing unit or level information. This address is not deliverable without unit/level information (Phantom Primary Point)",
                "example": "e.g. 35 Spring Street BONDI JUNCTION  NSW  2022 (adding “Level 99” for example to the address would make it acceptable)."
            },
            "Z": {
                "meaning": "Unit or level not found, and no corresponding Primary Point in the PAF",
                "example": "e.g. 1/67 Nerang St, NERANG QLD 4211"
            },
            "0": {
                "meaning": "Address correct"
            },
            "1": {
                "meaning": "Address matched to primary point only.",
                "example": "There is secondary address information (such as level, unit number and/or house suffix) that could not be found in the PAF, so the DPID for primary point (the address without any secondary information) was returned."
            },
            "2": {
                "meaning": "Address didn’t exactly match, but was left as is.",
                "example": "AMAS rules allow an incorrect locality to be kept in the address, if it exists within the postcode. Similarly, a border locality, some synonym localities or an alternate street name can be kept. This code can only be returned if the flag in 'DpidGrammar.txt' is set to keep these address elements. The Amended Flag code will indicate which address elements differ from the record in the PAF but weren't corrected. For example, ('The Rocks' is incorrect, but is a locality in the 2000 postcode in 309 Kent St, The Rocks NSW 2000"
            },
            "4": {
                "meaning": "Address amended."
            },
            "8": {
                "meaning": "Address amended on second pass.",
                "example": "Applies only to the Dpid3Pass grammar file, indicating that the address couldn’t be given a DPID according to AMAS matching rules, but after address enhancement, a DPID was found."
            },
            "16": {
                "meaning": "Address matched to street only.",
                "example": "The street number provided doesn’t exist in the PAF, but the PAF does contain a Group Delivery ID for the street. This Group Delivery ID is returned in the DPID field."
            },
            "32": {
                "meaning": "Address matched to locality only.",
                "example": "The street provided doesn’t exist in the PAF, but the PAF does contain a Locality Delivery ID, which is returned in the DPID field."
            },
            "64": {
                "meaning": "Additional sub address information exists. Additional secondary address information (such as level, unit number and/or house suffix) is available for that address."
            },
            "128": {
                "meaning": "Geocode match only.",
                "example": "All matching records had same Geocode, so are returning the found Geocode, even though a unique address cannot be found. "
            }
        };
    }
})();
