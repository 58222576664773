(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('StationeryDetailController', StationeryDetailController);

    StationeryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Stationery', 'User', 'AuthServerProvider'];

    function StationeryDetailController($scope, $rootScope, $stateParams, entity, Stationery, User, AuthServerProvider) {
        var vm = this;

        vm.stationery = entity;
        vm.token = AuthServerProvider.getToken().access_token;

        var unsubscribe = $rootScope.$on('crm2PrintApp:stationeryUpdate', function(event, result) {
            vm.stationery = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
