(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('CostCenter', CostCenter);

    CostCenter.$inject = ['$resource', 'DateUtils'];

    function CostCenter ($resource, DateUtils) {
        var resourceUrl =  '/api/cost-centers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'confirmInvite': {
                method:'POST',
                url:'/api/cost-centers/:id/confirm-invite',
                params: {id:"@id"}
            },
            'sendInvite': {
                method:'POST',
                url:'/api/cost-centers/:id/send-invite',
                params: {id:"@id"}
            },
            'withChargeToUser': {
                method:'GET', isArray: true,
                url:'/api/cost-centers/with-charge-to-user'
            }
        });
    }
})();
