(function() {
    'use strict';

    angular.module('crm2PrintApp')
        .controller('FieldMappingModalController', FieldMappingModalController);

    FieldMappingModalController.$inject = ['$scope', '$uibModalInstance', 'mapping', 'mappingScheme'];

    function FieldMappingModalController ($scope, $uibModalInstance, mapping, mappingScheme) {
        var vm = this;

        vm.save = save;
        vm.mapping = mapping;
        vm.mapping.mode = !!mapping.expression  ? 'EXPRESSION' : 'FIELD';
        vm.mappingScheme = mappingScheme;
        vm.updateFieldMappingMode = updateFieldMappingMode;
        vm.createCustomField = createCustomField;

        function updateMapableFields() {
            vm.mapableFields = vm.mappingScheme.availableFields.map(function(f){
                return {
                    field: f,
                    group: 'Standard'
                }
            }).concat(vm.mappingScheme.customFields.map(function(f){
                return {
                    field: f,
                    group: 'User Defined'
                }
            }));
        }

        function updateFieldMappingMode(item) {
            if(!vm.mapping.mappedField) {
                //TODO mark required
                return;
            }
            if(vm.mappingScheme.customFields.indexOf(vm.mapping.mappedField) >= 0) {
                vm.mapping.mode = 'CUSTOM';
            } else {
                vm.mapping.mode = 'FIELD'
            }
        }

        function createCustomField(detectAddress) {
            swal({
                title: 'Create Custom Field',
                text: detectAddress
                    ? "The system will attempt to detect an address and save the parts into separate fields"
                    : null,
                input: 'text',
                inputPlaceholder: 'Field Name',
                showCancelButton: true,
                confirmButtonText: "Create",
                closeOnCancel: true
            }).then(function(result){
                if (result.value) {
                    var fld = result.value.trim();
                    addField(fld, false, detectAddress);
                    if(detectAddress) {
                        addField(fld+" Address1", true);
                        addField(fld+" City", true);
                        addField(fld+" Postcode", true);
                        addField(fld+" State", true);
                        addField(fld+" Country Code", true);
                    }
                    updateMapableFields();
                }
            });
        }

        function addField(name, generated, detectAddress) {
            vm.mappingScheme.fields.push({
                "valid": true,
                "display": true,
                "mode": "CUSTOM",
                "readOnly": true,
                "templateField": name,
                "detectAddress": detectAddress,
                "generated": generated
            });
            vm.mappingScheme.customFields.push(name);
        }

        function save() {
            if(vm.mapping.mode == 'FIELD' || vm.mapping.mode == 'CUSTOM' ) {
                delete vm.mapping.expression;
            } else {
                delete vm.mapping.mappedField;
            }
            vm.mapping.match = 'MANUAL';
            $uibModalInstance.dismiss('cancel');
            $scope.$emit('template-change');
        }

        updateMapableFields();
    }
})();
