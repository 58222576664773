(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('Vendor', Vendor);

    Vendor.$inject = ['$resource'];

    function Vendor ($resource) {
        var resourceUrl =  '/api/vendors/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'current': {
                method: 'GET',
                url: '/api/vendors/current',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'vendorLimitationsForCountry': {
                method: 'GET', isArray: true,
                url: '/api/vendors/country/:countryCode3/limitations'
            },
            'checkVendorAvailabilityForPrintJob': {
                method: 'GET', isArray: true,
                url: '/api/vendors/country/:countryCode3/print-job/:printJobId/check?letter-count=:letterCount'
            },
            'update': { method:'PUT' }
        });
    }
})();
