(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .factory('PrintAndMailCostSearch', PrintAndMailCostSearch);

    PrintAndMailCostSearch.$inject = ['$resource'];

    function PrintAndMailCostSearch($resource) {
        var resourceUrl =  '/api/_search/print-and-mail-costs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
