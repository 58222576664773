(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('SiteUserController', SiteUserController);

    SiteUserController.$inject = ['$scope', '$state', 'SiteUser', 'SiteUserSearch'];

    function SiteUserController ($scope, $state, SiteUser, SiteUserSearch) {
        var vm = this;
        
        vm.siteUsers = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            SiteUser.query(function(result) {
                vm.siteUsers = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SiteUserSearch.query({query: vm.searchQuery}, function(result) {
                vm.siteUsers = result;
            });
        }    }
})();
