(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('UserPricingController', UserPricingController);

    UserPricingController.$inject = ['$scope', '$state', 'UserPricing', 'UserPricingSearch'];

    function UserPricingController ($scope, $state, UserPricing, UserPricingSearch) {
        var vm = this;
        
        vm.userPricings = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            UserPricing.query(function(result) {
                vm.userPricings = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            UserPricingSearch.query({query: vm.searchQuery}, function(result) {
                vm.userPricings = result;
            });
        }    }
})();
