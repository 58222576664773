(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('ContactUsController', ContactUsController);

    ContactUsController.$inject = ['$scope', 'Principal', 'ContactService', '$state'];

    function ContactUsController ($scope, Principal, ContactService, $state) {
        var vm = this;

        vm.message = {};
        vm.contactUs = contactUs;

        Principal.identity().then(function(account) {
            vm.account = account;
            if(Principal.isAuthenticated()) {
                vm.message.firstName = account.firstName;
                vm.message.lastName = account.lastName;
                vm.message.email = account.email;
                vm.message.userId = account.id;
                vm.message.login = account.login;
            }
        });

        function contactUs () {
            ContactService.contactUs(vm.message,function (r) {
               swal("Sent","Your message has been sent.","success");
            });
            // $state.go('register');
        }
    }
})();
