(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('SiteCustomisationDetailController', SiteCustomisationDetailController);

    SiteCustomisationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SiteCustomisation', 'Site'];

    function SiteCustomisationDetailController($scope, $rootScope, $stateParams, entity, SiteCustomisation, Site) {
        var vm = this;

        vm.siteCustomisation = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:siteCustomisationUpdate', function(event, result) {
            vm.siteCustomisation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
