(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('TemplateDetailController', TemplateDetailController);

    TemplateDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Template', 'Envelope', 'Site', 'User', 'Stationery', 'SiteDataSource', 'AuthServerProvider', 'Principal', '$uibModal'];

    function TemplateDetailController($scope, $rootScope, $stateParams, entity, Template, Envelope, Site, User, Stationery, SiteDataSource, AuthServerProvider, Principal, $uibModal) {
        var vm = this;

        vm.template = entity;
        vm.token = AuthServerProvider.getToken().access_token;

        Principal.identity().then(function(account) {
            vm.account = account;
            vm.isAuthenticated = Principal.isAuthenticated;
        });

        $scope.$on('template-change', function (event) {
            vm.showSave = true;
        });

        vm.canEdit = function() {
            return vm.template.creator.login == vm.account.login || Principal.hasAuthority('ROLE_ADMIN');
        };

        var unsubscribe = $rootScope.$on('crm2PrintApp:templateUpdate', function(event, result) {
            vm.template = result;
        });

        vm.subsequentPageIndicies = function() {
          var indicies = [];
          for (var i = 1; i < vm.template.numberOfPages; i++) {
              indicies.push(i)
          }
          return indicies
        };
        vm.share = function () {
            Template.share(vm.template,function(result) {
                $rootScope.$emit('crm2PrintApp:templateUpdate', result);
            });
        };
        vm.makePublic = function () {
            Template.makePublic(vm.template,function(result) {
                $rootScope.$emit('crm2PrintApp:templateUpdate', result);
            });
        };
        vm.clearCacheAndPreviews = function () {
            Template.clearCache({id:vm.template.id});
        };
        vm.editFieldMapping = function(mapping) {
            if(!vm.mappingScheme) {
                Template.extractFields({id: vm.template.id}, function (resp) {
                    vm.mappingScheme = resp.mappingScheme;
                    vm.editFieldMapping(mapping);
                });
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/template/field-mapping.modal.html',
                controller: 'FieldMappingModalController',
                controllerAs: 'vm',
                size: 'lg',
                scope: $scope,
                resolve: {
                    mapping: function() {
                        return mapping;
                    },
                    mappingScheme: function() {
                        return vm.mappingScheme;
                    }
                }
            });
        };

        vm.saveFields = function () {
            Template.saveFields({id:vm.template.id, fields: vm.template.fields},function () {
                vm.showSave = false;
            });
        };

        $scope.$on('$destroy', unsubscribe);
    }
})();
