(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('SiteDataSourceDetailController', SiteDataSourceDetailController);

    SiteDataSourceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SiteDataSource', 'User', 'Site'];

    function SiteDataSourceDetailController($scope, $rootScope, $stateParams, entity, SiteDataSource, User, Site) {
        var vm = this;

        vm.siteDataSource = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:siteDataSourceUpdate', function(event, result) {
            vm.siteDataSource = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
