(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('SiteTokenDetailController', SiteTokenDetailController);

    SiteTokenDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SiteToken', 'SiteUser'];

    function SiteTokenDetailController($scope, $rootScope, $stateParams, entity, SiteToken, SiteUser) {
        var vm = this;

        vm.siteToken = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:siteTokenUpdate', function(event, result) {
            vm.siteToken = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
