(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('VendorStaffDialogController', VendorStaffDialogController);

    VendorStaffDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'VendorStaff', 'Vendor', 'User'];

    function VendorStaffDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, VendorStaff, Vendor, User) {
        var vm = this;

        vm.vendorStaff = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.vendors = Vendor.query();
        vm.users = User.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.vendorStaff.id !== null) {
                VendorStaff.update(vm.vendorStaff, onSaveSuccess, onSaveError);
            } else {
                VendorStaff.save(vm.vendorStaff, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('crm2PrintApp:vendorStaffUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.created = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
