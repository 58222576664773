(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope', '$state', '$cookies',  'Auth', 'Principal', 'ProfileService', 'LoginService', 'SplashScreenService', 'UserProfile'];

    function NavbarController ($rootScope, $state, $cookies, Auth, Principal, ProfileService, LoginService, SplashScreenService, UserProfile) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.costCenterInviteToken = $cookies.get('cost_center_invite');

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerDisabled = response.swaggerDisabled;
        });

        $rootScope.$on('authenticationSuccess', function() {
            getUser();
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.showWelcomeGuide = showWelcomeGuide;
        vm.$state = $state;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function getUser() {
            Principal.identity().then(function(user) {
                vm.user = user;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.showWelcomeGuide(true);
                if(user.timestamps) {
                    if(user.timestamps.lastViewedWelcome) {
                        user.timestamps.lastViewedWelcome = Date.parse(user.timestamps.lastViewedWelcome)
                    }
                }
            });
            UserProfile.get(function(resp){
                vm.userProfile = resp;
                vm.showWelcomeGuide(true);
            });
        }

        function showWelcomeGuide(autoShow) {
            if(autoShow) {
                // debugger;
                if(!vm.user
                    || !vm.userProfile
                    || !vm.userProfile.showWelcomeGuide
                    /*|| vm.user.timestamps.lastViewedWelcome > Date.now()*/) {
                    return
                }
            }
            SplashScreenService.open(vm.userProfile);
        }
    }
})();
