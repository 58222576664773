(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobHistoryDetailController', PrintJobHistoryDetailController);

    PrintJobHistoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PrintJobHistory', 'PrintJob', 'Invoice'];

    function PrintJobHistoryDetailController($scope, $rootScope, $stateParams, entity, PrintJobHistory, PrintJob, Invoice) {
        var vm = this;

        vm.printJobHistory = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:printJobHistoryUpdate', function(event, result) {
            vm.printJobHistory = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
