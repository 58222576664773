(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('ManifestRow', ManifestRow);

    ManifestRow.$inject = ['$resource'];

    function ManifestRow ($resource) {
        var resourceUrl =  '/api/manifest-rows/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
