(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('Site', Site);

    Site.$inject = ['$resource', 'DateUtils'];

    function Site ($resource, DateUtils) {
        var resourceUrl =  '/api/sites/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryWithApi': {
                method: 'GET',
                url: '/api/sites?api-only=true',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                    }
                    return data;
                }
            },
            'authenticationMethod': {
                method: 'GET',
                url: '/api/sites/:siteId/authentication-method',
                params: {siteId:"@siteId"}
            },
            'oauthLoginUrl': {
                method: 'GET',
                url: '/api/sites/:siteId/oauth-login-url',
                params: {siteId:"@siteId"}
            },
            'oauthSiteToken': {
                method: 'GET',
                url: '/api/sites/:siteId/oauth-site-token',
                params: {siteId:"@siteId"}
            },
            'update': { method:'PUT' }
        });
    }
})();
