(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('TemplateEditController', TemplateEditController);

    TemplateEditController.$inject = ['$timeout', '$cookies', '$scope', '$state', '$stateParams', 'entity', '$uibModal', 'Template', 'TemplateDoc', 'Envelope', 'Site', 'SiteDataSource', 'User', 'UserProfile', 'Stationery'];

    function TemplateEditController ($timeout, $cookies, $scope, $state, $stateParams, entity, $uibModal, Template, TemplateDoc, Envelope, Site, SiteDataSource, User, UserProfile, Stationery) {

        Dropzone.autoDiscover = false;

        var vm = this;
        vm.template = entity;
        vm.envelopes = Envelope.query();
        // vm.sites = Site.query();
        SiteDataSource.queryLinked(function(resp){
            vm.dataSources = resp;
            vm.hideCRMLinkWarning = false;
            vm.dataSources.forEach(function(dataSource) {
                dataSource.label = dataSource.siteName + " - "+dataSource.name;
                if(dataSource.status != 'ACTIVE') {
                    dataSource.label += " ["+dataSource.status+"]"
                }
                vm.hideCRMLinkWarning |= dataSource.requiresUser;
            });
        });
        vm.templates = Template.query();
        vm.stationeries = Stationery.query();
        vm.allChecked = true;
        vm.windowLessEnvelope = false;
        UserProfile.get(function (resp) {
            if(!resp.postcode || !resp.countryId) {
                swal({
                    title: "User Profile Incomplete",
                    text: "Please complete your user profile before proceeding with creating templates as your geographical location is required to determine which local mail houses offer which services.",
                    type: "warning",
                    closeOnConfirm: true
                }).then(function(){
                    $state.go('user-profile');
                });
            }
        });

        vm.categories = [
            "Just Listed"
            ,"Just Sold"
            ,"Auction"
            ,"Birthday"
            ,"Anniversary"
            ,"Market Appraisal"
            ,"Market Report"
            ,"Recent Sales"
            ,"Local Expertise"
            ,"Property Specific"
            ,"Buyers Ready"
            ,"Thinking of Selling"
            ,"Expiring Listing"
            ,"Expired Listing"
            ,"Withdrawn Listing"
            ,"Property Comparison"
            ,"Referrals"
            ,"Newsletter"
            ,"FSBO"
            ,"Internet Lead"
            ,"Farming"
            ,"Open House Follow Up"
            ,"Rising Market"
            ,"Buyers"
            ,"Sellers"
            ,"Competition"
            ,"Thank you"
            ,"Other"
        ];
        vm.showSampleAddress = false;

        $scope.dropzoneConfig = {
            'options': { // passed into the Dropzone constructor
                'url': '/api/assets/templates',
                dictDefaultMessage: 'Upload new template.<div class="file-formats"><i class="fa fa-file-word-o"></i></div>',
                maxFiles: 1,
                parallelUploads: 1,
                maxFilesize : 10,
                uploadMultiple: false,
                // acceptedFiles: '.docx,.doc,.rtf'
                acceptedFiles: '.docx,.doc'
            },
            'eventHandlers': {
                'sending': function (file, xhr, formData) {
                    // console.log("sending: "+file);
                },
                'success': function (file, response) {
                    // console.log(response);
                    $scope.dropzoneConfig.dropzone.removeFile(file);
                    vm.doc = response;
                    vm.approvedCode = generateApprovalCode(5);
                    vm.template.name = vm.doc.originalFilename.substr(0,vm.doc.originalFilename.lastIndexOf('.')).replace("_"," ");
                    // vm.previewImg = $(file.previewElement).addClass("doc-preview").find(".dz-image img").get(0);
                    vm.updatePreview();
                    vm.extractFields();
                    swal({title:"Template Uploaded",
                        text:"Please inspect the preview and remove all mail merge fields that overlap the address box by un-checking the corresponding field name in the list.",
                        type:"success"
                    }).then(vm.initTour);
                }
            }
        };

        function generateApprovalCode(length) {
            var code = '';
            while (code.length < length) {
                code = Math.random().toString(36).replace(/[^a-z]+/g, '');
            }
            return code.substr(0, length);
        }

        $scope.dropzoneConfigStationery = {
            'options': { // passed into the Dropzone constructor
                'url': '/api/assets/templates',
                dictDefaultMessage: 'Upload new stationery or letterhead.<div class="file-formats"><i class="fa fa-file-pdf-o"></i><i class="fa fa-file-word-o"></i></div>',
                maxFiles: 1,
                parallelUploads: 1,
                maxFilesize : 2,
                uploadMultiple: false,
                // acceptedFiles: '.docx,.doc,.rtf'
                acceptedFiles: '.jpg,.pdf,.docx,.doc,.rtf'
            },
            'eventHandlers': {
                'sending': function (file, xhr, formData) {
                    // console.log("sending: "+file);
                },
                'success': function (file, response) {
                    $scope.dropzoneConfigStationery.dropzone.removeFile(file);
                    Stationery.save({name: response.originalFilename, file: response.key}, function(stationery){
                        vm.stationeries.push(stationery);
                        vm.selectStationery(stationery)
                    }, function(resp){
                        swal("Stationery upload failed",resp.statusText,"error");
                    });
                }
            }
        };

        vm.previewStationery = function(stationery) {
            //todo clear dropbox
            //vm.previewStationeryImg = $(file.previewElement).addClass("doc-preview").find(".dz-image img").get(0);
            vm.stationeryPreviewUrl = "/api/assets/stationeries/"+stationery.id+"-full.png";
            vm.previewUrl = vm.generatePreviewUrl();
            vm.previewUrlPDF = vm.generatePreviewUrlPDF();
        };

        vm.selectStationery = function(stationery) {
            vm.stationery = vm.template.stationery = stationery;
            vm.previewStationery(stationery);
        };

        vm.tickAll = function () {
            vm.getUserMergeFields().forEach(function(f){
                f.display = vm.allChecked;
            });
            vm.updatePreviewTrigger();
        };

        vm.dataSourceSelected = function() {
            vm.extractFields();
            if(vm.template.dataSource && vm.template.dataSource.disclaimer) {
                swal("Data Source Disclaimer",vm.template.dataSource.disclaimer,"info");
            }
        };

        vm.envelopeSelected = function() {
            vm.windowLessEnvelope = !vm.template.envelope.hasWindow;
            if(vm.windowLessEnvelope) {
                if(!vm.hasOwnProperty('addressBoxSpaceBackup')) {
                    vm.addressBoxSpaceBackup = vm.template.addressBoxSpace;
                    vm.template.addressBoxSpace = false;
                    swal({
                        title: "Premium Feature",
                        text: "Window-less and hand written envelopes will attract a higher cost per letter.",
                        type: "warning"
                    })
                }
            } else {
                if(vm.hasOwnProperty('addressBoxSpaceBackup')) {
                    vm.template.addressBoxSpace = vm.addressBoxSpaceBackup;
                    delete vm.addressBoxSpaceBackup;
                }
            }
        };

        vm.extractFields = function() {
            TemplateDoc.extractFields({id:vm.doc.key,dataSourceId:vm.template.dataSource ? vm.template.dataSource.id : ''}, function(resp) {
                vm.mappingScheme = resp.mappingScheme;
                if(vm.mappingScheme) {
                    vm.template.fields = vm.mappingScheme.fields;
                }
                vm.template.size = resp.pageSize;
                if(vm.template.size != 'A4') {
                    swal({
                        title: "Template is not A4!",
                        text: "This template is of "+vm.template.size+" paper size. Please ensure your document is " +
                                "A4 to prevent unexpected layout issues during printing.",
                        type: "warning"
                    });
                }
                vm.template.numberOfPages = resp.pageCount;
                vm.template.wordCount = resp.wordCount;
                vm.template.documentText = resp.text;
                vm.template.documentLastSavedBy = resp.lastSavedBy;
                vm.template.containsNonFixedPositionBleeds = resp.containsNonFixedPositionBleeds;
                vm.template.addressBoxSpace = resp.addressBoxSpace;
                if(vm.template.containsNonFixedPositionBleeds) {
                    swal({
                        title: "Potential Layout Issue!!!",
                        html: "Your template contains bleed (i.e. objects touching the edge of the page), but that are" +
                            " not positioned relative to the page edge. This can cause layout issues if bleed is required." +
                            " Please check that all bleeds are positioned correctly on the preview PDF." +
                            " See <a href='#/faq' target='_blank'>FAQ &gt; Bleed</a> how to update your template to prevent this warning.",
                        type: "warning"
                        // showCancelButton: true,
                        // confirmButtonText: "Would you like to highlight the bleeds",
                        // cancelButtonText: "No, just take me back to my templates.",
                        // closeOnConfirm: true,
                        // closeOnCancel: true
                    }).then(function(isConfirm){
                        $scope.$emit('template-change');
                    });
                } else {
                    // vm.template.documentLastSavedTime = resp.lastSavedTime;
                    $scope.$emit('template-change');
                }
            }, function(res) {
                //no fields found
            });
        };

        vm.getStandardMergeFields = function() {
            if(!vm.template.fields) return [];
            return vm.template.fields.filter(function(f){
                return f.templateField.startsWith("c2p_");
            })
        };
        vm.getUserMergeFields = function() {
            if(!vm.template.fields) return [];
            return vm.template.fields.filter(function(f){
                return !f.templateField.startsWith("c2p_");
            })
        };

        vm.getUnMappedFields = function() {
            if(!vm.template.fields
                || !vm.dataSources.adapterName  // no adapter => fields as per template
            ) return [];
            return vm.template.fields.filter(function(f){
                return f.display && !f.readOnly && !f.mappedField && !f.expression && f.mode != 'CUSTOM';
            });
        };

        vm.updatePreviewTrigger = function() {
            if(vm.updatePreviewPromise) $timeout.cancel(vm.updatePreviewPromise);
            vm.updatePreviewPromise = $timeout(vm.updatePreview,500);
        };

        vm.getHiddenFieldnames = function () {
            return !vm.mappingScheme || !vm.template.fields ? [] : vm.template.fields
                .filter(function(f){ return !f.display;})
                .map(function(f){return f.templateField;});
        };
        vm.getMappedFieldnames = function (isExpr) {
            return !vm.mappingScheme || !vm.template.fields ? [] : vm.template.fields
                .filter(function(f){
                    return f.display && (f.mode=='EXPRESSION' || (!isExpr && !!f.mappedField && f.templateField != f.mappedField));
                })
                .map(function(f){
                    return f.templateField+"=>"+(f.mode=='FIELD' ? f.mappedField : f.expression);
                });
        };
        vm.generatePreviewUrl = function () {
            return "/api/assets/templates/"+vm.doc.key+"/preview?hideFieldnames="+encodeURIComponent(vm.getHiddenFieldnames().join("|"))
                +"&mappedFieldnames="+encodeURIComponent(vm.getMappedFieldnames().join("|"))
                +"&sampleAddress="+vm.showSampleAddress
                +"&dataSourceId="+(vm.template.dataSource ? vm.template.dataSource.id : '')
                +"&addressBlock="+(vm.template.addressBoxSpace || true);
        };
        vm.generatePreviewUrlPDF = function () {
            return vm.generatePreviewUrl()
                    .replace("/preview?","/preview.pdf?")
                    .replace("&sampleAddress=true","&sampleAddress=false")
                + "&stationery="+(vm.stationery ? vm.stationery.id : "")
                + "&approvalCode="+vm.approvedCode;
        };
        vm.updatePreview = function () {
            delete vm.updatePreviewPromise;
            var newUrl = vm.generatePreviewUrl();
            if (newUrl != vm.previewUrl) {
                $("#final-preview").addClass("loading");
                vm.previewUrl = vm.generatePreviewUrl();
                vm.previewUrlPDF = vm.generatePreviewUrlPDF();
            }
        };

        vm.showLetterMatchingSurchargeWarning = function() {
            if(vm.template.addressBoxSpace === false) {
                swal({
                    title: "Letter matching surcharge!!!",
                    html: "Letters that do not have an address block cannot use window envelopes and require extra processing.\n" +
                        " Additional charges will apply.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, accept surcharge and use non-window envelope.",
                    cancelButtonText: "No, use window-envelope.",
                    closeOnConfirm: true,
                    closeOnCancel: true
                }).then(function(resp){
                    if (!resp.value) {
                        vm.template.addressBoxSpace = true;
                    }
                    vm.updatePreviewTrigger();
                });
            }
            vm.updatePreviewTrigger();
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            Template.updateTemplateDoc({
                templateId: result.id,
                docId: vm.doc.key,
                hideFieldnames: vm.getHiddenFieldnames().join("|"),
                sampleAddress: vm.showSampleAddress,
                stationery: (vm.stationery ? {id: vm.stationery.id} : null),
                approvalCode: vm.approvedCode
            },function(){
                $scope.$emit('crm2PrintApp:templateUpdate', result);
                // $uibModalInstance.close(result);
                vm.isSaving = false;
                swal({
                    title: "Success!!!",
                    html: "Your new template has been saved.<br/>Would you like to start a new Print Job based on this template?",
                    type: "success",
                    showCancelButton: true,
                    confirmButtonText: "Yes, start new Print Job.",
                    cancelButtonText: "No, just take me back to my templates.",
                    closeOnConfirm: true,
                    closeOnCancel: true
                }).then(function(resp){
                    if (resp.value) {
                        $state.go('print-job.new',{templateId:result.id});
                    } else {
                        $state.go('template');
                    }
                });
            },function(){
                debugger;
                alert("fail");
            })
        };

        vm.verifyApprovedCode = function() {
            vm.approved = !!vm.template.approvedCode && vm.template.approvedCode.toLowerCase() != vm.approvedCode.toLowerCase();
        };

        vm.onPasteCode = function() {
            $timeout(function() {
                $("#field_approvedCode").blur();
            })
        };

        var onSaveError = function (resp) {
            vm.isSaving = false;
            swal("Saving of the template failed",resp.statusText,"error");
        };

        vm.editFieldMapping = function(mapping) {
            $uibModal.open({
                templateUrl: 'app/entities/template/field-mapping.modal.html',
                controller: 'FieldMappingModalController',
                controllerAs: 'vm',
                size: 'lg',
                scope: $scope,
                resolve: {
                    mapping: function() {
                        return mapping;
                    },
                    mappingScheme: function() {
                        return vm.mappingScheme;
                    }
                }
            });
        };

        vm.save = function () {
            if(vm.getUnMappedFields().length > 0) {
                swal("Unmapped Fields!","Please ensure that all merge fields are either mapped or hidden.","error");
                return;
            }
            vm.isSaving = true;
            //vm.template.categories = $("form[name=step1_form] select").val()
            if (vm.template.id !== null) {
                Template.update(vm.template, onSaveSuccess, onSaveError);
            } else {
                Template.save(vm.template, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            // $uibModalInstance.dismiss('cancel');
            vm.template = {};
            delete vm.doc;
            delete vm.stationery;
            delete vm.previewUrl;
            delete vm.previewUrlPDF;
            delete vm.stationeryPreviewUrl
            delete vm.mappingScheme;
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.created = false;
        vm.datePickerOpenStatus.validated = false;
        vm.datePickerOpenStatus.approved = false;
        vm.datePickerOpenStatus.lastModified = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        $scope.$on('template-change',vm.updatePreviewTrigger.bind(vm));
        /*$scope.$on('template-change',function(){
            vm.displayExpressionWarning = vm.containsExpression();
        });*/

        vm.initTour = function () {
            //http://linkedin.github.io/hopscotch/
            var tour = {
                id: "hs-templateEdit",
                steps: [
                    {
                        title: "Begin here",
                        content: "Give your template a descriptive name, by typing it in this field.",
                        target: ["field_name"],
                        placement: "right"
                    },
                    {
                        title: "Tag your template",
                        content: "To quickly search for your template later, save it under a specific category.",
                        target: ["field_category"],
                        placement: "right"
                    },
                    {
                        title: "Select your CRM provider",
                        content: "Click on the box and choose your CRM or list provider. If your CRM is not listed here <a href='/contact-us' target='_blank'>contacts us</a> so we can add it.",
                        target: ["field_datasource"],
                        placement: "right"
                    },
                    {
                        title: "Map Address Fields",
                        content: "CRM2Print has already pre-matched the address block fields to the appropriate fields from the selected list provider. Expand the list by clicking on the down-arrow and then click on the link button next to the mail merge field to verify or update the merge field.",
                        target: ["standard_merge_fields_block"],
                        placement: "right"
                    },
                    {
                        title: "Remove Duplicate Address Merge Fields",
                        content: "Untick all other address merge fields to remove them from your template's address block. You may also map your custom fields to those available by the selected CRM or list provider.",
                        target: ["user_defined_fields_block"],
                        placement: "right"
                    },
                    {
                        title: "Preview Sample",
                        content: "Tick this box to preview your template with sample contact details. Note: sample addresses are not available for all data source.",
                        target: ["field_sample_address_label"],
                        placement: "right"
                    },
                    {
                        title: "Add Stationary or Letterhead",
                        content: "If your template does not contain a letterhead, click this button to select or upload your letterhead or stationery PDF/DOC/RTF file",
                        target: ["template-options"],
                        placement: "left"
                    },
                    {
                        title: "Preview Template Output",
                        content: "If you agree to the terms click the two boxes and then ‘Preview PDF’ to verify your template is formatted correctly.  The template will open in a new tab for your review.",
                        target: ["previewTemplateButton"],
                        placement: "left"
                    },
                    {
                        title: "Approval Code",
                        content: "On the top left of the template preview is an approval code. Copy this code and if you are satisfied with the template paste it into the 'Approval Code' field and then click SAVE.",
                        target: ["field_approvedCode"],
                        placement: "bottom",
                        xOffset: -150
                    }
                ],
                showPrevButton: true,
                scrollTopMargin: 100,
                delay: 300,
                onEnd: function () {
                    hopscotch.endTour(false, false);//clearCookie=false
                    $cookies.put(tour.id,"-1");
                },
                onClose: function () {
                    hopscotch.endTour(false, false);//clearCookie=false
                    $cookies.put(tour.id,"-1");
                }
            };
            var startStep = $cookies.get(tour.id);
            if (startStep == undefined  || startStep >= 0) {
                $cookies.remove(tour.id);
                hopscotch.startTour(tour, startStep);
            }
        };

    }
})();
