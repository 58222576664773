(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .factory('PostalAccountSearch', PostalAccountSearch);

    PostalAccountSearch.$inject = ['$resource'];

    function PostalAccountSearch($resource) {
        var resourceUrl =  '/api/_search/postal-accounts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
