(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .factory('CostCenterSearch', CostCenterSearch);

    CostCenterSearch.$inject = ['$resource'];

    function CostCenterSearch($resource) {
        var resourceUrl =  '/api/_search/cost-centers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
