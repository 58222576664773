(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'paginationConstants', 'JhiLanguageService', 'AuthServerProvider', '$http' ,'$localStorage'];

    function UserManagementController(Principal, User, ParseLinks, paginationConstants, JhiLanguageService, AuthServerProvider, $http, $localStorage) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_APIUSER', 'ROLE_VENDOR', 'ROLE_DEMO_MODE'];
        vm.clear = clear;
        vm.currentAccount = null;
        vm.languages = null;
        vm.links = null;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.loadPage = loadPage;
        vm.page = 1;
        vm.setActive = setActive;
        vm.totalItems = null;
        vm.users = [];
        vm.token = AuthServerProvider.getToken().access_token;
        vm.impersonate = impersonate;

        vm.loadAll();


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });


        function loadAll () {
            User.query({page: vm.page - 1, size: paginationConstants.itemsPerPage}, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');

                //hide anonymous user from user management: it's a required user for Spring Security
                for(var i in result) {
                    if(result[i]['login'] === 'anonymoususer') {
                        result.splice(i,1);
                    }
                }
                vm.users = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            User.search({query: vm.searchQuery}, function(result) {
                //hide anonymous user from user management: it's a required user for Spring Security
                for(var i in result) {
                    if(result[i]['login'] === 'anonymoususer') {
                        result.splice(i,1);
                    }
                }
                vm.users = result;
            });
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function setActive (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function clear () {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
            vm.editForm.$setPristine();
            vm.editForm.$setUntouched();
        }


        function impersonate(user) {
            return $http.post("/login/impersonate?username="+user.login+"&access_token="+vm.token).success(authSuccess);

            function authSuccess (response) {
                var expiredAt = new Date();
                expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
                response.expires_at = expiredAt.getTime();
                $localStorage.authenticationToken = response;
                location.href = "/";
                return response;
            }
        }

    }
})();
