(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('print-and-mail-cost', {
            parent: 'entity',
            url: '/print-and-mail-cost',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'crm2PrintApp.printAndMailCost.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-and-mail-cost/print-and-mail-costs.html',
                    controller: 'PrintAndMailCostController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printAndMailCost');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('print-and-mail-cost-detail', {
            parent: 'entity',
            url: '/print-and-mail-cost/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'crm2PrintApp.printAndMailCost.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-and-mail-cost/print-and-mail-cost-detail.html',
                    controller: 'PrintAndMailCostDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printAndMailCost');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PrintAndMailCost', function($stateParams, PrintAndMailCost) {
                    return PrintAndMailCost.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('print-and-mail-cost.new', {
            parent: 'print-and-mail-cost',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-and-mail-cost/print-and-mail-cost-dialog.html',
                    controller: 'PrintAndMailCostDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                pageCount: null,
                                duplex: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('print-and-mail-cost', null, { reload: true });
                }, function() {
                    $state.go('print-and-mail-cost');
                });
            }]
        })
        .state('print-and-mail-cost.edit', {
            parent: 'print-and-mail-cost',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-and-mail-cost/print-and-mail-cost-dialog.html',
                    controller: 'PrintAndMailCostDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PrintAndMailCost', function(PrintAndMailCost) {
                            return PrintAndMailCost.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('print-and-mail-cost', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('print-and-mail-cost.delete', {
            parent: 'print-and-mail-cost',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-and-mail-cost/print-and-mail-cost-delete-dialog.html',
                    controller: 'PrintAndMailCostDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PrintAndMailCost', function(PrintAndMailCost) {
                            return PrintAndMailCost.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('print-and-mail-cost', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
