(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobRecordDetailController', PrintJobRecordDetailController);

    PrintJobRecordDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PrintJobRecord', 'PrintJob', 'PrintStream'];

    function PrintJobRecordDetailController($scope, $rootScope, $stateParams, entity, PrintJobRecord, PrintJob, PrintStream) {
        var vm = this;

        vm.printJobRecord = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:printJobRecordUpdate', function(event, result) {
            vm.printJobRecord = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
