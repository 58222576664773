(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobDetailController', PrintJobDetailController);

    PrintJobDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PrintJob', 'PrintJobHistory', 'Site', 'User', 'VendorJobTicket', 'Template', 'Stationery','PrintJobCharge', 'Country', 'Account', 'UserProfile'];

    function PrintJobDetailController($scope, $rootScope, $stateParams, entity, PrintJob, PrintJobHistory, Site, User, VendorJobTicket, Template, Stationery, PrintJobCharge, Country, Account, UserProfile) {
        var vm = this;

        vm.printJob = entity;
        entity.$promise.then(function (printJob) {
            if(printJob.paymentStatus === 'DUE') {
                vm.due = true;
            }
        });
        vm.printJobHistory = [];
        vm.printJobCharges = [];
        vm.countries = Country.query(function (resp) {
            vm.countryLookup = {};
            resp.forEach(function (c) {
                vm.countryLookup[c.code3] = c;
            });
            return resp;
        });
        vm.user = Account.get({userId:vm.printJob.userId});
        vm.userProfile = UserProfile.getByUserId({userId:vm.printJob.userId});
        if(vm.printJob.stationeryId != null) {
            vm.stationery = Stationery.get({id: vm.printJob.stationeryId});
        }

        vm.loadAll = function() {

            PrintJob.history({printJobId:vm.printJob.id}, function(result) {
                vm.printJobHistory = result;
                result.forEach(function(log){
                    if(log.code === 'PLACED') {
                        vm.placed = log;
                    } else if(log.code === 'MERGE_READY' || vm.isMailMerged(log.code)) {
                        vm.inProgress = log;
                    } else if(log.code === 'COMPLETED') {
                        vm.completed = log;
                    };
                });
            });

            PrintJob.charges({printJobId:vm.printJob.id}, function(result){
                vm.printJobCharges = result;
                vm.paid = true;
                vm.due = true;
                result.forEach(function(charge){
                    vm.paid &= charge.invoiceStatus === 'PAID';
                    vm.due &= charge.invoiceStatus === 'ISSUED' || charge.invoiceStatus === 'RAISED';
                });
            });
        };

        vm.isMailMerged = function (status) {
            return status === 'MAIL_MERGE' || status === 'PRINT_READY' || status === 'MAIL_HOUSE' || status === 'PRINTED'
        };


        var unsubscribe = $rootScope.$on('crm2PrintApp:printJobUpdate', function(event, result) {
            vm.printJob = result;
        });


        $scope.$on('$destroy', unsubscribe);
        vm.loadAll();
    }
})();
