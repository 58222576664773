(function () {
    'use strict';

    angular
        .module('crm2PrintApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('/api/users/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'search': {
                method:'GET',
                url:'/api/_search/users/:query',
                isArray: true
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'billingAccount': {
                method:'GET',
                url:'/api/billing-account'
            },
            'deleteBillingAccount': {
                method:'DELETE',
                url:'/api/billing-account'
            },
            'updateLastViewedWelcome': {
                method:'POST',
                url:'/api/user/track/views/welcome-guide'
            },
            'updateBillingAccount': {
                method:'PUT',
                url:'/api/billing-account?stripe-token=:stripeToken',
                params: {stripeToken:"@stripeToken"}
            },
            'sendActivationEmail': {
                method:'POST',
                url:'/api/users/:id/send-activation-email',
                params: {id:"@id"}
            },
            'reIndexAllUsers': {
                method:'POST',
                url:'/api/users/re-index'
            },
            'syncMailchimp': {
                method:'POST',
                url:'/api/users/sync-mailchimp'
            }
        });

        return service;
    }
})();
