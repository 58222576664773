(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', 'User', 'AuthServerProvider', '$http', '$localStorage'];

    function UserManagementDetailController ($stateParams, User, AuthServerProvider, $http, $localStorage) {
        var vm = this;

        vm.load = load;
        vm.impersonate = impersonate;
        vm.sendActivationEmail = sendActivationEmail;
        vm.user = {};
        vm.token = AuthServerProvider.getToken().access_token;

        vm.load($stateParams.id);

        function load (id) {
            User.get({id: id}, function(result) {
                vm.user = result;
            });
        }


        function impersonate() {
            return $http.post("/login/impersonate?username="+vm.user.login+"&access_token="+vm.token).success(authSuccess);

            function authSuccess (response) {
                var expiredAt = new Date();
                expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
                response.expires_at = expiredAt.getTime();
                $localStorage.authenticationToken = response;
                location.href = "/";
                return response;
            }
        }

        function sendActivationEmail() {
            User.sendActivationEmail({id: vm.user.id}, function(result) {
                //vm.user = result;
                swal("Sent", "Activate Email has been sent to "+result.email, "info");
            });
        }
    }
})();
