(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('VendorStaffDetailController', VendorStaffDetailController);

    VendorStaffDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'VendorStaff', 'Vendor', 'User'];

    function VendorStaffDetailController($scope, $rootScope, $stateParams, entity, VendorStaff, Vendor, User) {
        var vm = this;

        vm.vendorStaff = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:vendorStaffUpdate', function(event, result) {
            vm.vendorStaff = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
