(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('SiteToken', SiteToken);

    SiteToken.$inject = ['$resource', 'DateUtils'];

    function SiteToken ($resource, DateUtils) {
        var resourceUrl =  '/api/site-tokens/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                        data.expiryDate = DateUtils.convertDateTimeFromServer(data.expiryDate);
                        data.refreshTokenExpiryDate = DateUtils.convertDateTimeFromServer(data.refreshTokenExpiryDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'generate': {
                method:'POST',
                url :'/api/site-tokens/generate'
            }
        });
    }
})();
