(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('print-stream', {
            parent: 'entity',
            url: '/print-stream?page&sort&search&type&printjobid',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'crm2PrintApp.printStream.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-stream/print-streams.html',
                    controller: 'PrintStreamController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null,
                type: {
                    value: 'FINAL'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printStream');
                    $translatePartialLoader.addPart('printStreamType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('print-stream-detail', {
            parent: 'entity',
            url: '/print-stream/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'crm2PrintApp.printStream.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-stream/print-stream-detail.html',
                    controller: 'PrintStreamDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printStream');
                    $translatePartialLoader.addPart('printStreamType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PrintStream', function($stateParams, PrintStream) {
                    return PrintStream.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('print-stream.edit', {
            parent: 'print-stream',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-stream/print-stream-dialog.html',
                    controller: 'PrintStreamDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PrintStream', function(PrintStream) {
                            return PrintStream.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('print-stream', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('print-stream.delete', {
            parent: 'print-stream',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-stream/print-stream-delete-dialog.html',
                    controller: 'PrintStreamDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PrintStream', function(PrintStream) {
                            return PrintStream.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('print-stream', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
