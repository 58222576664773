(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobChargeDetailController', PrintJobChargeDetailController);

    PrintJobChargeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PrintJobCharge', 'PrintJob', 'Invoice'];

    function PrintJobChargeDetailController($scope, $rootScope, $stateParams, entity, PrintJobCharge, PrintJob, Invoice) {
        var vm = this;

        vm.printJobCharge = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:printJobChargeUpdate', function(event, result) {
            vm.printJobCharge = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
