(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('ReturnToSenderDetailController', ReturnToSenderDetailController);

    ReturnToSenderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ReturnToSender', 'Vendor', 'PrintJobRecord', 'VendorStaff'];

    function ReturnToSenderDetailController($scope, $rootScope, $stateParams, entity, ReturnToSender, Vendor, PrintJobRecord, VendorStaff) {
        var vm = this;

        vm.returnToSender = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:returnToSenderUpdate', function(event, result) {
            vm.returnToSender = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
