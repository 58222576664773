(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'UserPricing'];

    function HomeController ($scope, Principal, LoginService, $state, UserPricing) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.userPricings = [];
        vm.login = LoginService.open;
        vm.register = register;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        loadAll();


        function loadAll() {
            getAccount();

            UserPricing.query(function(result) {
                var lookup = {};
                result.forEach(function(up){
                    lookup[up.currency+up.duplex+up.pageCount+up.envelopeCode] = up;
                });
                vm.userPricings = result.filter(function(up){
                    return up.currency === 'AUD'
                        && !up.duplex
                        && up.envelopeCode === 'DLX'
                        && up.pageCount <= 5
                        && up.minQuantity === 1
                        && !up.addressedEnvelope;
                }).map(function(up){
                    var duplex = lookup[up.currency+true+up.pageCount+up.envelopeCode];
                    if(duplex) {
                        up.duplexPrice = duplex.amount;
                    }
                    return up
                });
            });
        }
        //App.initHelpers(['appear', 'appear-countTo']);

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
