(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintAndMailCostController', PrintAndMailCostController);

    PrintAndMailCostController.$inject = ['$scope', '$state', 'PrintAndMailCost', 'PrintAndMailCostSearch'];

    function PrintAndMailCostController ($scope, $state, PrintAndMailCost, PrintAndMailCostSearch) {
        var vm = this;
        
        vm.printAndMailCosts = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            PrintAndMailCost.query(function(result) {
                vm.printAndMailCosts = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PrintAndMailCostSearch.query({query: vm.searchQuery}, function(result) {
                vm.printAndMailCosts = result;
            });
        }    }
})();
