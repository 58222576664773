(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('UserProfile', UserProfile);

    UserProfile.$inject = ['$resource', 'DateUtils'];

    function UserProfile ($resource, DateUtils) {
        var resourceUrl =  '/api/user-profiles/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                url: '/api/user-profile',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.approverEmailVerified = DateUtils.convertDateTimeFromServer(data.approverEmailVerified);
                    }
                    return data;
                }
            },
            'getByUserId': {
                method: 'GET',
                url: '/api/user-profiles/:userId',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.approverEmailVerified = DateUtils.convertDateTimeFromServer(data.approverEmailVerified);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'markGuideAsSeen': {
                method: 'POST',
                url: '/api/user-profile/toggle-show-welcome-guide'
            }
        });
    }
})();
