(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('UserProfileDialogController', UserProfileDialogController);

    UserProfileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'UserProfile', 'Country', 'User', 'Vendor', 'Principal', 'Stationery'];

    function UserProfileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, UserProfile, Country, User, Vendor, Principal, Stationery) {
        var vm = this;

        vm.userProfile = entity;
        vm.clear = clear;
        vm.save = save;
        vm.countries = Country.query();
        vm.billingAccount = User.billingAccount();
        vm.stationeries = Stationery.query();

        Principal.hasAuthority('ROLE_DEMO_MODE').then(function (demoMode) {
            $scope.demoMode = demoMode;
            if(window.Stripe) {//prevent errors during offline debugging
                Stripe.setPublishableKey(demoMode ? 'pk_test_NAwIc50T7FTa46UoeswbkTqb' : 'pk_live_PS4h0U30PcdeIywnhy7yYrj6');
            }
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            UserProfile.update(vm.userProfile, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('crm2PrintApp:userProfileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.selectedCountry = function() {
            vm.countries.forEach(function (c) {
                if(c.id == vm.userProfile.countryId) {
                    vm.country = c;
                }
            })
            if(vm.country) {
                Vendor.vendorLimitationsForCountry({countryCode3:vm.country.code3},function(resp){
                    if(resp.length == 0) {
                        vm.vendorLimitation = "At the moment no Mailhouses are available for "+vm.country.name+". " +
                            "You may still explore CRM2Print to be ready for when Print & Mail becomes available";
                        swal("No Mailhouses available yet!",vendorLimitation,"error");
                    } else if(resp[0] != "") {
                        vm.vendorLimitation = "A Mailhouse is available in "+vm.country.name+", but it has some limitations: "+resp[0];
                        swal("Limitations apply!",vm.vendorLimitation,"warning");
                    }
                });
            }
        }


        vm.hasExistingSource = function() {
            return !!vm.billingAccount && !!vm.billingAccount.sources && vm.billingAccount.sources.totalCount >= 1;
        };
        vm.getExistingSource = function() {
            return vm.hasExistingSource() ? vm.billingAccount.sources.data[0] : null;
        };

        vm.deleteCard = function () {
            User.deleteBillingAccount(function (resp) {
                swal("Deleted!!", "Credit card deleted.", "success");
                delete vm.billingAccount.sources;
            });
        };

        $scope.handleStripe = function(status, response){
            if(response.error) {
                // there was an error. Fix it.
                swal("Payment Type Verification Error", response.error, "error");
            } else {
                vm.billingAccount = User.updateBillingAccount({stripeToken: response.id}, function (resp) {
                    swal("Success!!", "Credit Card saved.", "success");
                });
            }
        };

        $timeout(function (){
            // Init Card, for more info and examples you can check out https://github.com/jessepollak/card
            jQuery('.js-card-form').card({
                container: '.js-card-container',
                formSelectors: {
                    numberInput: '#cc_number',
                    expiryInput: '#cc_expiry',
                    cvcInput: '#cc_cvc',
                    nameInput: '#cc_name'
                }
            });
        });


    }
})();
