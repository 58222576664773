(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintStreamDeleteController',PrintStreamDeleteController);

    PrintStreamDeleteController.$inject = ['$uibModalInstance', 'entity', 'PrintStream'];

    function PrintStreamDeleteController($uibModalInstance, entity, PrintStream) {
        var vm = this;

        vm.printStream = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PrintStream.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
