(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobChargeDialogController', PrintJobChargeDialogController);

    PrintJobChargeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PrintJobCharge', 'PrintJob', 'Invoice'];

    function PrintJobChargeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PrintJobCharge, PrintJob, Invoice) {
        var vm = this;

        vm.printJobCharge = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.printjobs = PrintJob.query();
        vm.invoices = Invoice.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.printJobCharge.id !== null) {
                PrintJobCharge.update(vm.printJobCharge, onSaveSuccess, onSaveError);
            } else {
                PrintJobCharge.save(vm.printJobCharge, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('crm2PrintApp:printJobChargeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.paymentDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
