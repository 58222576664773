(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .factory('VendorJobTicketSearch', VendorJobTicketSearch);

    VendorJobTicketSearch.$inject = ['$resource'];

    function VendorJobTicketSearch($resource) {
        var resourceUrl =  '/api/_search/vendor-job-tickets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
