(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobController', PrintJobController);

    PrintJobController.$inject = ['$scope', '$state', 'PrintJob', 'PrintJobSearch', '$uibModal'];

    var printJobStatusIcon = {
        "null": "",
        "NEW": "si si-plus",
        "PLACED": "si si-basket-loaded",
        "MERGE_READY": "si si-docs text-warning",
        "MAIL_MERGE": "si si-docs text-success",
        "PRINT_READY": "si si-layers",
        "MAIL_HOUSE": "si si-printer",
        "DUE": "fa fa-hourglass-1 text-warning",
        "PAID": "fa fa-tick text-success",
        "PRINTED": "si si-envelope",
        "POSTED": "si si-paper-plane",
        "COMPLETED": "si si-check",
        "ERROR": "si si-ban",
        "CANCELLED": "si si-close",
        "ARCHIVED": "si si-drawer"
    };

    function PrintJobController ($scope, $state, PrintJob, PrintJobSearch, $uibModal) {
        var vm = this;

        vm.printJobs = [];
        vm.search = search;
        vm.status = $state.params.status;

        loadAll();

        function loadAll() {
            PrintJob.query({status:vm.status},function(result) {
                vm.printJobs = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PrintJobSearch.query({query: vm.searchQuery}, function(result) {
                vm.printJobs = result;
            });
        }

        vm.statusIcon = function(status) {
            return printJobStatusIcon[status];
        }

        vm.selectAll = function() {
            vm.printJobs.forEach(function (pj) {
                pj.selected = vm.selectAllTick;
            });
        }

        vm.deleteSelected = function() {
            var ids = vm.printJobs.filter(function(pj) {
                return pj.selected;
            }).map(function(pj) {
                return pj.id;
            });
            // $state.go('print-job.delete-selected',{ids:ids});
            $uibModal.open({
                templateUrl: 'app/entities/print-job/print-job-delete-dialog.html',
                controller: 'PrintJobDeleteSelectedController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    ids: ['$stateParams', function() {
                        return ids;
                    }]
                }
            }).result.then(function() {
                $state.go('print-job', null, { reload: true });
            }, function() {
                $state.go('^');
            });

        }
    }
})();
