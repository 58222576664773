(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('Template', Template)
        .factory('TemplateDoc', TemplateDoc);

    Template.$inject = ['$resource', 'DateUtils'];

    function Template ($resource, DateUtils) {
        var resourceUrl =  '/api/templates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.created = DateUtils.convertDateTimeFromServer(data.created);
                    data.validated = DateUtils.convertDateTimeFromServer(data.validated);
                    data.approved = DateUtils.convertDateTimeFromServer(data.approved);
                    data.lastModified = DateUtils.convertDateTimeFromServer(data.lastModified);
                    data.documentLastSavedTime = DateUtils.convertDateTimeFromServer(data.documentLastSavedTime);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'archive': { method:'DELETE', url: '/api/templates/:id?archive=true', },
            'updateTemplateDoc': {
                url: '/api/templates/:id/document',
                params: {id:"@templateId"},
                method:'PUT'
            },
            'share': {
                url: '/api/templates/:id/share',
                params: {id:"@id"},
                method:'PUT'
            },
            'makePublic': {
                url: '/api/templates/:id/make-public',
                params: {id:"@id"},
                method:'PUT'
            },
            'clearCache': {
                url: '/api/templates/:id/cache',
                params: {id:"@id"},
                method:'DELETE'
            },
            'extractFields': {
                url: '/api/templates/:id/fieldnames',
                method: 'GET'
            },
            'saveFields': {
                url: '/api/templates/:id/fields',
                params: {id:"@id"},
                method: 'PUT'
            }
        });
    }

    TemplateDoc.$inject = ['$resource'];

    function TemplateDoc ($resource) {
        var resourceUrl =  '/api/assets/templates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.documentLastSavedTime = DateUtils.convertLocalDateToServer(data.documentLastSavedTime);
                    return angular.toJson(data);
                }
            },
            'extractFields': {
                url: '/api/assets/templates/:id/fieldnames?data-source-id=:dataSourceId',
                method: 'GET'
            },
            'substituteExpressions': {
                url: '/api/assets/templates/:id/substitute-expressions?data-source-id=:dataSourceId',
                method: 'GET'
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.documentLastSavedTime = DateUtils.convertLocalDateToServer(data.documentLastSavedTime);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
