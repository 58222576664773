(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('print-job-record', {
            parent: 'entity',
            url: '/print-job-record?printjobid',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'crm2PrintApp.printJobRecord.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-job-record/print-job-records.html',
                    controller: 'PrintJobRecordController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printJobRecord');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('print-job-record-detail', {
            parent: 'entity',
            url: '/print-job-record/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'crm2PrintApp.printJobRecord.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-job-record/print-job-record-detail.html',
                    controller: 'PrintJobRecordDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printJobRecord');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PrintJobRecord', function($stateParams, PrintJobRecord) {
                    return PrintJobRecord.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('print-job-record.new', {
            parent: 'print-job-record',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-job-record/print-job-record-dialog.html',
                    controller: 'PrintJobRecordDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                barcode: null,
                                sortPlan: null,
                                dpid: null,
                                bsp: null,
                                recipient1: null,
                                recipient2: null,
                                address1: null,
                                address2: null,
                                city: null,
                                state: null,
                                postcode: null,
                                country: null,
                                pageCount: null,
                                receivedReturnMail: null,
                                flag: null,
                                countryCode: null,
                                addressId: null,
                                printStreamPage: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('print-job-record', null, { reload: true });
                }, function() {
                    $state.go('print-job-record');
                });
            }]
        })
        .state('print-job-record.edit', {
            parent: 'print-job-record',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-job-record/print-job-record-dialog.html',
                    controller: 'PrintJobRecordDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PrintJobRecord', function(PrintJobRecord) {
                            return PrintJobRecord.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('print-job-record', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('print-job-record.delete', {
            parent: 'print-job-record',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-job-record/print-job-record-delete-dialog.html',
                    controller: 'PrintJobRecordDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PrintJobRecord', function(PrintJobRecord) {
                            return PrintJobRecord.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('print-job-record', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
