(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('AdminDashboardController', AdminDashboardController);

    AdminDashboardController.$inject = ['$timeout','$scope', '$uibModal', 'PrintJob', 'AdminDashboard', 'User', 'VendorJobTicket'];

    function AdminDashboardController ($timeout, $scope, $uibModal, PrintJob, AdminDashboard, User, VendorJobTicket) {
        var vm = this;
        vm.demo = false;
        vm.due = true;
        vm.refresh = refresh;
        vm.processMergeReadyPrintJobs = processMergeReadyPrintJobs;
        vm.processMergeReadyPrintJob = processMergeReadyPrintJob;
        vm.processPrintJobPrintStreams = processPrintJobPrintStreams;
        vm.deleteApiAbandoned = deleteApiAbandoned;
        vm.clearHibernateCache = AdminDashboard.clearHibernateCache;
        vm.reIndexAllUsers = User.reIndexAllUsers;
        vm.syncMailchimp = User.syncMailchimp;
        vm.markJobTicketsComplete = VendorJobTicket.markJobTicketsComplete;
        vm.cancelPrintJob = cancelPrintJob;

        vm.refresh();

        vm.merged = [];
        vm.collated = [];

        $scope.$watch('vm.metrics', function (newValue) {
        });

        function refresh () {
            vm.updatingMetrics = true;

            if(vm.due) {
                AdminDashboard.getOpenInvoices(vm.demo).then(function (resp) {
                    vm.invoices = resp;
                });
            } else {
                AdminDashboard.getPaidInvoices(1, vm.demo).then(function (resp) {
                    vm.invoices = resp;
                });
            }
            AdminDashboard.getInvoiceSummary('',7,vm.demo).then(function (resp) {
                vm.raised7days = resp;
            });
            AdminDashboard.getInvoiceSummary('',0,vm.demo).then(function (resp) {
                vm.raised24hours = resp;
            });
            /*AdminDashboard.getPrintJobSummary(7,vm.demo).then(function (resp) {
                vm.placed7days = resp;
            });
            AdminDashboard.getPrintJobSummary(0,vm.demo).then(function (resp) {
                vm.placed24hours = resp;
            });*/
            AdminDashboard.getPrintJobStats(vm.demo).then(function (resp) {
                vm.placedStats = resp;
                vm.placedStatsLookup = resp['by-period'].reduce(function(i,e){
                    i[e.period] = e;
                    return i;
                },{});
            });
            AdminDashboard.getPrintJobs(vm.demo).then(function (resp) {
                vm.printJobs = resp;
                vm.printJobCountByStatus = resp.reduce(function(stats,pj){
                   stats[pj.status] = (stats[pj.status] || 0) + 1;
                   return stats;
                },{});
            });
            PrintJob.findApiAbandonedSummary(function(resp) {
                vm.apiAbandonedCount = resp.count;
            });
            refreshQueueStats();
        }

        //this function will refresh the queue stats until the queues are idle
        function refreshQueueStats() {
            PrintJob.queueStats(function(resp){
                vm.queueStats = resp.reduce(function(initial,e) {
                    initial[e.name]=e;
                    initial.waiting+=e.waiting;
                    initial.active+=e.active;
                    return initial;
                },{waiting:0,active:0});
                if(vm.queueStats.active > 0 || vm.queueStats.waiting > 0) {
                    vm.queueProcessing = true;
                    $timeout(refreshQueueStats,3000);
                }
                if(vm.queueProcessing && vm.queueStats.active == 0 && vm.queueStats.waiting == 0) {
                    vm.queueProcessing = false;
                    $timeout(refresh,5);
                }
            });
        }


        function cancelPrintJob(id) {
            swal({
                title: "Cancel Printjob.",
                text: "Are you sure you want to cancel print job #"+id+"?\n Ensure to refund any payments.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No.",
                closeOnConfirm: true,
                closeOnCancel: true
            }).then(function(result){
                if (result.value) {
                    PrintJob.cancel({printJobId:id});
                }
            });
        }

        function processMergeReadyPrintJobs(demo) {
            PrintJob.processMergeReady({demo:demo}, refreshQueueStats);
        }

        function processMergeReadyPrintJob(id) {
            PrintJob.mailMerge({printJobId:id}, refreshQueueStats);
        }

        function processPrintJobPrintStreams(demo) {
            PrintJob.collateMerged({demo:demo}, refreshQueueStats);
        }

        function deleteApiAbandoned() {
            PrintJob.deleteApiAbandoned(function(resp) {
                swal("Done.","Delete all API Abandoned Print Jobs","success");
                vm.deletingApiAbandoned = false;
                PrintJob.findApiAbandonedSummary(function(resp) {
                    vm.apiAbandonedCount = resp.count;
                });
            });
            vm.deletingApiAbandoned = true;
        }

        function refreshThreadDumpData () {
            //JhiMetricsService.threadDump().then(function(data) {
                $uibModal.open({
                    templateUrl: 'app/admin/dashboard/admin-dashboard.modal.html',
                    controller: 'AdminDashboardModalController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        threadDump: function() {
                            return data;
                        }
                    }
                });
            //});
        }


    }
})();
