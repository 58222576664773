(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobRecordController', PrintJobRecordController);

    PrintJobRecordController.$inject = ['$scope', '$state', 'PrintJobRecord', 'PrintJobRecordSearch'];

    function PrintJobRecordController ($scope, $state, PrintJobRecord, PrintJobRecordSearch) {
        var vm = this;

        vm.printJobRecords = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            if(!$state.params.printjobid) return;
            PrintJobRecord.query({printjobid: $state.params.printjobid}, function(result) {
                vm.printJobRecords = result;
            });
        }

        function search () {
            if (!vm.searchQueryRecipient || !vm.searchQueryAddress1) {
                return vm.loadAll();
            }
            PrintJobRecordSearch.query({recipient: vm.searchQueryRecipient, address1: vm.searchQueryAddress1}, function(result) {
                vm.printJobRecords = result;
            });
        }
    }
})();
