(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('VendorJobTicketController', VendorJobTicketController);

    VendorJobTicketController.$inject = ['$scope', '$state', 'VendorJobTicket', 'VendorJobTicketSearch'];

    function VendorJobTicketController ($scope, $state, VendorJobTicket, VendorJobTicketSearch) {
        var vm = this;

        vm.vendorJobTickets = [];
        vm.search = search;
        vm.sendNewTickets = sendNewTickets;

        loadAll();

        function loadAll() {
            VendorJobTicket.query(function(result) {
                vm.vendorJobTickets = result;
            });
        }

        function sendNewTickets() {
            VendorJobTicket.sendUndelivered(function (result) {
                swal("Done!","Queued "+result.count+" Jobtickets for delivery", "success");
            })
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            VendorJobTicketSearch.query({query: vm.searchQuery}, function(result) {
                vm.vendorJobTickets = result;
            });
        }    }
})();
