(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('CostCenterSendInviteDialogController', CostCenterSendInviteDialogController);

    CostCenterSendInviteDialogController.$inject = ['$uibModalInstance', 'entity', 'CostCenter'];

    function CostCenterSendInviteDialogController($uibModalInstance, entity, CostCenter) {
        var vm = this;

        vm.costCenter = entity;
        vm.clear = clear;
        vm.sendInvite = sendInvite;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function sendInvite (id) {
            CostCenter.sendInvite({id: id, email: vm.email},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
