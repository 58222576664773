(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .factory('EnvelopeSearch', EnvelopeSearch);

    EnvelopeSearch.$inject = ['$resource'];

    function EnvelopeSearch($resource) {
        var resourceUrl =  '/api/_search/envelopes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
