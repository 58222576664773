(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('site-customisation', {
            parent: 'entity',
            url: '/site-customisation',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'crm2PrintApp.siteCustomisation.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/site-customisation/site-customisations.html',
                    controller: 'SiteCustomisationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('siteCustomisation');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('site-customisation-detail', {
            parent: 'entity',
            url: '/site-customisation/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'crm2PrintApp.siteCustomisation.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/site-customisation/site-customisation-detail.html',
                    controller: 'SiteCustomisationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('siteCustomisation');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SiteCustomisation', function($stateParams, SiteCustomisation) {
                    return SiteCustomisation.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('site-customisation.new', {
            parent: 'site-customisation',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/site-customisation/site-customisation-dialog.html',
                    controller: 'SiteCustomisationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                siteCustomisationId: null,
                                headerCss: null,
                                headerHtml: null,
                                footerHtml: null,
                                userUrlPattern: null,
                                printUrlPattern: null,
                                lastModified: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('site-customisation', null, { reload: true });
                }, function() {
                    $state.go('site-customisation');
                });
            }]
        })
        .state('site-customisation.edit', {
            parent: 'site-customisation',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/site-customisation/site-customisation-dialog.html',
                    controller: 'SiteCustomisationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SiteCustomisation', function(SiteCustomisation) {
                            return SiteCustomisation.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('site-customisation', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('site-customisation.delete', {
            parent: 'site-customisation',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/site-customisation/site-customisation-delete-dialog.html',
                    controller: 'SiteCustomisationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SiteCustomisation', function(SiteCustomisation) {
                            return SiteCustomisation.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('site-customisation', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
