(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('site-token', {
            parent: 'entity',
            url: '/site-token',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'crm2PrintApp.siteToken.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/site-token/site-tokens.html',
                    controller: 'SiteTokenController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('siteToken');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('site-token-detail', {
            parent: 'entity',
            url: '/site-token/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'crm2PrintApp.siteToken.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/site-token/site-token-detail.html',
                    controller: 'SiteTokenDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('siteToken');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SiteToken', function($stateParams, SiteToken) {
                    return SiteToken.get({id : $stateParams.id});
                }]
            }
        })
        .state('site-token.new', {
            parent: 'site-token',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/site-token/site-token-dialog.html',
                    controller: 'SiteTokenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                siteTokenId: null,
                                authenticationMethod: null,
                                clientId: null,
                                clientSecret: null,
                                accessToken: null,
                                creationDate: null,
                                expiryDate: null,
                                refreshToken: null,
                                refreshTokenExpiryDate: null,
                                id: null
                            };
                        },
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/sweetalert2/sweetalert2.min.js', 'content/js/plugins/sweetalert/sweetalert.css'
                                ]
                            });
                        }]
                    }
                }).result.then(function() {
                    $state.go('site-token', null, { reload: true });
                }, function() {
                    $state.go('site-token');
                });
            }]
        })
        .state('site-token.edit', {
            parent: 'site-token',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/site-token/site-token-dialog.html',
                    controller: 'SiteTokenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SiteToken', function(SiteToken) {
                            return SiteToken.get({id : $stateParams.id});
                        }],
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/sweetalert2/sweetalert2.min.js', 'content/js/plugins/sweetalert/sweetalert.css'
                                ]
                            });
                        }]
                    }
                }).result.then(function() {
                    $state.go('site-token', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('site-token.delete', {
            parent: 'site-token',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/site-token/site-token-delete-dialog.html',
                    controller: 'SiteTokenDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SiteToken', function(SiteToken) {
                            return SiteToken.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('site-token', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
