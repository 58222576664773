(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('print-jobs', {
            parent: 'entity',
            url: '/print-jobs/{status}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'crm2PrintApp.printJob.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-job/print-jobs.html',
                    controller: 'PrintJobController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printJob');
                    $translatePartialLoader.addPart('chargeStatus');
                    $translatePartialLoader.addPart('orderStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('print-job', {
            parent: 'entity',
            url: '/print-job',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'crm2PrintApp.printJob.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-job/print-jobs.html',
                    controller: 'PrintJobController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printJob');
                    $translatePartialLoader.addPart('orderStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('print-job-detail', {
            parent: 'entity',
            url: '/print-job/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'crm2PrintApp.printJob.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-job/print-job-detail.html',
                    controller: 'PrintJobDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printJob');
                    $translatePartialLoader.addPart('orderStatus');
                    $translatePartialLoader.addPart('printJobHistory');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PrintJob', function($stateParams, PrintJob) {
                    return PrintJob.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('print-job.new', {
            parent: 'print-job',
            url: '/new/{templateId}?isTemplate',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-job/print-job-edit.html',
                    controller: 'PrintJobEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printJob');
                    $translatePartialLoader.addPart('orderStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams','$filter', function($stateParams,$filter) {
                    return {
                        reference: "CRM2Print Print & Mail Order - "+$filter('date')(new Date(),'dd/MM/yyyy HH:mm'),
                        invoiceEmail: null,
                        status: "NEW",
                        created: new Date(),
                        lastModified: null,
                        letterCount: null,
                        pageCount: null,
                        duplex: null,
                        priceNet: null,
                        tax: null,
                        priceGross: null,
                        id: null,
                        templateId: $stateParams.templateId ? parseInt($stateParams.templateId) : null,
                        inserts: null,
                        contactsFilter: {}
                    };
                }]
            }
        })
        .state('print-job.edit', {
            parent: 'print-job',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/print-job/print-job-edit.html',
                    controller: 'PrintJobEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('printJob');
                    $translatePartialLoader.addPart('orderStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PrintJob', function($stateParams, PrintJob) {
                    return PrintJob.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('print-job.delete', {
            parent: 'print-job',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-job/print-job-delete-dialog.html',
                    controller: 'PrintJobDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PrintJob', function(PrintJob) {
                            return PrintJob.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('print-job', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('print-job.delete-selected', {
            parent: 'print-job',
            url: '/delete/selected',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/print-job/print-job-delete-dialog.html',
                    controller: 'PrintJobDeleteSelectedController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        ids: ['$stateParams', function($stateParams) {
                            return {
                                ids: $stateParams.ids
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('print-job', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
