(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('SSOController', SSOController);

    SSOController.$inject = ['$rootScope', '$state', '$stateParams', 'Auth', 'LoginService', '$localStorage'];

    function SSOController ($rootScope, $state, $stateParams, Auth, LoginService, $localStorage) {
        var vm = this;
        Auth.ssoLogin($stateParams.token).then(function(){
            $state.go("template");
        });
    }
})();
