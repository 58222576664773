(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('WidgetPreviewController', WidgetPreviewController);

    WidgetPreviewController.$inject = ['$scope', 'Principal', '$state', '$http'];

    function WidgetPreviewController ($scope, Principal, $state, $http) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;

        vm.credentials = {
            grant_type: 'password',
            username: '',
            password: ''
        };

        vm.getToken = function () {
            $.ajax('/oauth/token', {
                method: 'POST',
                data: vm.credentials,
                headers: vm.header()
            }).done(function (resp) {
                vm.token = resp;
                vm.preview();
                $scope.$digest();
            }).fail(function (resp) {
                alert("An Expected Error has occurred. "+resp);
            });
        };

        vm.preview = function () {
            vm.previewUrl = "/api/widget?access_token=" + vm.token.access_token+ "&cache_buster=" + new Date().getTime();
        };

        vm.header = function() {
            return {
                "Authorization": "Basic " + btoa(vm.clientId + ":" + vm.clientSecret)
            };
        };


    }
})();
