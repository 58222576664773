(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('PrintJob', PrintJob)
        .factory('ListCategory', ListCategory)
        .factory('SitePrecincts', SitePrecincts)
        .factory('SiteSubUsers', SiteSubUsers)
        .factory('Contact', Contact);

    PrintJob.$inject = ['$resource', 'DateUtils'];

    function PrintJob ($resource, DateUtils) {
        var resourceUrl =  '/api/print-jobs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true,params:{'status':'@status'}, url: '/api/print-jobs/:id?status=:status'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                        data.lastModified = DateUtils.convertDateTimeFromServer(data.lastModified);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'deletePrintJobs': {
                method:'DELETE',
                url:'/api/print-jobs/selected'
            },
            'generatePreview': {
                method:'POST',
                url:'/api/print-jobs/:id/generate-preview',
                params: {id:"@printJobId"}
            },
            'generateMailMerge': {
                method:'POST',
                url:'/api/print-jobs/:id/generate-mailmerge',
                params: {id:"@printJobId"}
            },
            'validateContacts': {
                method:'POST',
                isArray: true,
                url:'/api/print-jobs/:id/validate-contacts',
                params: {id:"@printJobId"}
            },
            'saveContacts': {
                method:'PUT',//see loadContacts
                url:'/api/print-jobs/:id/contacts?json-only=:jsonOnly&print-job=:printJobId' +
                    '&site-user=:siteUserId' +
                    '&category=:categoryId' +
                    '&precinct=:precinctId' +
                    '&user=:subUserId' +
                    '&listname=:listName',
                params: {
                    id: "@printJobId", jsonOnly: "@jsonOnly",
                    siteUserId: "@siteUserId",
                    categoryId: "@categoryId",
                    listName: "@listName",
                    subUserId: "@subUserId",
                    precinctId: "@precinctId"
                }
            },
            'deleteContacts': {
                method:'DELETE',
                url:'/api/print-jobs/:printJobId/contacts',
            },
            'loadContacts': {
                method:'POST',//not using GET because params substitution... nfi
                url:'/api/print-jobs/:id/contacts',
                params: {id:"@printJobId"}
            },
            'sendApproverNotification': {
                method:'POST',
                url:'/api/print-jobs/:id/send-approver-notification',
                params: {id:"@printJobId"}
            },
            'checkout': {
                method:'POST',
                url:'/api/print-jobs/:id/checkout?stripe-token=:stripeToken',
                params: {id:"@id",stripeToken:"@stripeToken"}
            },
            'saveAsPrintJobTemplate': {
                method:'POST',
                url:'/api/print-jobs/:id/save-as-template?stripe-token=:stripeToken&subscribe=:subscribe',
                params: {id:"@id",stripeToken:"@stripeToken",subscribe:"@subscribe"}
            },
            'quote': {
                method:'POST',
                url:'/api/print-jobs/:id/quote',
                params: {id:"@printJobId"}
            },
            'loadQuote': {
                method:'GET',
                url:'/api/print-jobs/:printJobId/quote',
            },
            'unsubscribeFromContactsSource': {
                method:'POST',
                url:'/api/print-jobs/:id/unsubscribe',
                params: {id:"@printJobId"}
            },
            'pickupQuote': {
                method:'GET',
                url:'/api/quotes/:id',
                params: {id:"@id"}
            },
            'history': {
                method:'GET',
                url:'/api/print-jobs/:printJobId/history',
                isArray: true,
                params: {printJobId:"@printJobId"}
            },
            'charges': {
                method:'GET',
                url:'/api/print-jobs/:printJobId/charges',
                isArray: true,
                params: {printJobId:"@printJobId"}
            },
            'mailMerge': {
                method:'POST',
                url:'/api/print-jobs/:printJobId/mail-merge',
                params: {printJobId:"@printJobId"}
            },
            'cancel': {
                method:'POST',
                url:'/api/print-jobs/:printJobId/cancel',
                params: {printJobId:"@printJobId"}
            },
            'processMergeReady': {
                method:'POST',
                url:'/api/print-jobs/process-merge-ready?demo=:demo',
                params: {demo:"@demo"}
            },
            'collateMerged': {
                method:'POST',
                isArray: true,
                url:'/api/print-jobs/collate-merged?demo=:demo',
                params: {demo:"@demo"}
            },
            'findApiAbandoned': {
                method:'GET',
                isArray: true,
                url:'/api/print-jobs/abandoned-api-submitted'
            },
            'findApiAbandonedSummary': {
                method:'GET',
                url:'/api/print-jobs/abandoned-api-submitted/summary'
            },
            'deleteApiAbandoned': {
                method:'DELETE',
                url:'/api/print-jobs/abandoned-api-submitted'
            },
            'queueStats': {
                method:'GET',
                isArray: true,
                url:'/api/print-jobs/queue-stats'
            },
            'siteMessages': {
                method:'GET',
                url:'/api/site-messages'
            }
        });
    }

    ListCategory.$inject = ['$resource', 'DateUtils'];

    function ListCategory ($resource, DateUtils) {
        var resourceUrl =  '/api/site-data-sources/:dataSourceId/by-site-user/:siteUserId/list-categories?print-job=:printJobId';

        return $resource(resourceUrl, {siteUserId:"@siteUserId",dataSourceId:"@dataSourceId",printJobId:"@printJobId"}, {
            'query': { method: 'GET', isArray: true}
        });
    }

    SiteSubUsers.$inject = ['$resource', 'DateUtils'];

    function SiteSubUsers ($resource, DateUtils) {
        var resourceUrl =  '/api/site-data-sources/:dataSourceId/by-site-user/:siteUserId/site-sub-users';

        return $resource(resourceUrl, {siteUserId:"@siteUserId",dataSourceId:"@dataSourceId"}, {
            'query': { method: 'GET', isArray: true}
        });
    }

    SitePrecincts.$inject = ['$resource', 'DateUtils'];

    function SitePrecincts ($resource, DateUtils) {
        var resourceUrl =  '/api/site-data-sources/:dataSourceId/by-site-user/:siteUserId/precincts';

        return $resource(resourceUrl, {siteUserId:"@siteUserId",dataSourceId:"@dataSourceId"}, {
            'query': { method: 'GET', isArray: true}
        });
    }

    Contact.$inject = ['$resource', 'DateUtils'];

    function Contact ($resource, DateUtils) {
        var resourceUrl =  '/api/site-data-sources/:dataSourceId/by-site-user/:siteUserId/contacts?category=:categoryId&precinct=:precinctId&user=:subUserId&print-job=:printJobId&listname=:listName';

        return $resource(resourceUrl, {siteUserId:"@siteUserId", categoryId:"@categoryId", printJobId:"@printJobId", listName:"@listName", subUserId:"@subUserId", precinctId:"@precinctId"}, {
            'query': { method: 'GET'},
            'readFromCSV': {
                url: '/api/site-data-sources/:dataSourceId/csv/:docId/contacts?print-job=:printJobId&site-user=:siteUserId&list-name=:listName&template=:templateId',
                method: 'GET'
            }
        });
    }
})();
