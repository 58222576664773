(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('EnvelopeDialogController', EnvelopeDialogController);

    EnvelopeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Envelope', 'Vendor'];

    function EnvelopeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Envelope, Vendor) {
        var vm = this;

        vm.envelope = entity;
        vm.clear = clear;
        vm.save = save;
        vm.vendors = Vendor.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.envelope.id !== null) {
                Envelope.update(vm.envelope, onSaveSuccess, onSaveError);
            } else {
                Envelope.save(vm.envelope, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('crm2PrintApp:envelopeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
