(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('template', {
            parent: 'entity',
            url: '/template',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'crm2PrintApp.template.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/template/templates.html',
                    controller: 'TemplateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('template');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('template-detail', {
            parent: 'entity',
            url: '/template/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'crm2PrintApp.template.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/template/template-detail.html',
                    controller: 'TemplateDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('template');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Template', function($stateParams, Template) {
                    return Template.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('template.new', {
            parent: 'template',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/template/template-edit.html',
                    controller: 'TemplateEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('template');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        status: null,
                        name: null,
                        fileFormat: null,
                        size: null,
                        visibility: null,
                        created: null,
                        validated: null,
                        approved: null,
                        lastModified: null,
                        numberOfPages: null,
                        mailMergefields: null,
                        addressBoxSpace: true,
                        category: null,
                        approvedCode: null,
                        wordCount: null,
                        documentText: null,
                        documentLastSavedBy: null,
                        documentLastSavedTime: null,
                        shareToken: null,
                        stationeryFirstPageOnly: true,
                        id: null
                    };
                }
            }
        })
        .state('template.edit', {
            parent: 'template',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/template/template-edit.html',
                    controller: 'TemplateEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('template');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Template', function($stateParams, Template) {
                    return Template.get({id : $stateParams.id});
                }]
            }
        })
        .state('template.delete', {
            parent: 'template',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/template/template-delete-dialog.html',
                    controller: 'TemplateDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Template', function(Template) {
                            return Template.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('template', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
