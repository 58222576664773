(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PostalAccountController', PostalAccountController);

    PostalAccountController.$inject = ['$scope', '$state', 'PostalAccount', 'PostalAccountSearch'];

    function PostalAccountController ($scope, $state, PostalAccount, PostalAccountSearch) {
        var vm = this;
        vm.postalAccounts = [];
        vm.loadAll = function() {
            PostalAccount.query(function(result) {
                vm.postalAccounts = result;
            });
        };

        vm.search = function () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PostalAccountSearch.query({query: vm.searchQuery}, function(result) {
                vm.postalAccounts = result;
            });
        };
        vm.loadAll();
        
    }
})();
