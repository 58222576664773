(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('StationeryController', StationeryController);

    StationeryController.$inject = ['$scope', '$state', 'Stationery', 'StationerySearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function StationeryController ($scope, $state, Stationery, StationerySearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        Dropzone.autoDiscover = false;

        $scope.dropzoneConfigStationery = {
            'options': { // passed into the Dropzone constructor
                'url': '/api/assets/templates',
                dictDefaultMessage: 'Upload new stationery or letterhead.<div class="file-formats"><i class="fa fa-file-pdf-o"></i><i class="fa fa-file-word-o"></i></div>',
                maxFiles: 1,
                parallelUploads: 1,
                maxFilesize : 2,
                uploadMultiple: false,
                // acceptedFiles: '.docx,.doc,.rtf'
                acceptedFiles: '.jpg,.pdf,.docx,.doc,.rtf'
            },
            'eventHandlers': {
                'sending': function (file, xhr, formData) {
                    // console.log("sending: "+file);
                },
                'success': function (file, response) {
                    $scope.dropzoneConfigStationery.dropzone.removeFile(file);
                    Stationery.save({name: response.originalFilename, file: response.key}, function(stationery){
                        vm.stationeries.push(stationery);
                    }, function(resp){
                        swal("Stationery upload failed",resp.statusText,"error");
                    });
                }
            }
        };


        function loadAll () {
            if (pagingParams.search) {
                StationerySearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Stationery.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.stationeries = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
