(function() {
    'use strict';

    window.App = angular
        .module('crm2PrintApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'ui.bootstrap',
            'oc.lazyLoad',
            'infinite-scroll',
            'angular-loading-bar',
            'ui.select','ngSanitize',
            'angularPayments',
            'summernote'
            // jhipster-needle-angularjs-add-module JHipster will add new module here
        ])
        .run(run);

    run.$inject = ['$rootScope', 'uiHelpers', 'stateHandler', 'translationHandler', '$location', '$cookies', '$window'];

    function run($rootScope, uiHelpers, stateHandler, translationHandler, $location, $cookies, $window) {
        stateHandler.initialize();
        translationHandler.initialize();


        //OneUI start
        // Access uiHelpers easily from all controllers
        $rootScope.helpers = uiHelpers;

        // On window resize or orientation change resize #main-container & Handle scrolling
        var resizeTimeout;

        jQuery(window).on('resize orientationchange', function () {
            clearTimeout(resizeTimeout);

            resizeTimeout = setTimeout(function(){
                $rootScope.helpers.uiHandleScroll();
                $rootScope.helpers.uiHandleMain();
            }, 150);
        });

        //https://www.tjvantoll.com/2012/06/15/detecting-print-requests-with-javascript/
        var beforePrint = function() {
            Tawk_API.hideWidget();
        };
        var afterPrint = function() {
            Tawk_API.showWidget();
        };
        if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(function(mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });
        }
        window.onbeforeprint = beforePrint;
        window.onafterprint = afterPrint;

        /*var search = $location.search();
        if(search.utm_source) {
            var utmSource = search.utm_source.split(":");
            $cookies.put("utm."+utmSource[0],utmSource[1]);
            $location.search({}).replace();
        }*/

        $rootScope.utm = {
            source: $cookies.get('utm_source')
        };

        //Google Analytics
        $window.ga('create','UA-80464593-1');
        $rootScope.$on('$stateChangeSuccess', function (event) {
            $window.ga('send', 'pageview', $location.path());
        });
    }

    /*
     *  Document   : app.js
     *  Author     : pixelcave
     *  Description: Setting up and vital functionality for our App
     *
     */

// Router configuration
    App.config(['$stateProvider', '$urlRouterProvider',
        function ($stateProvider, $urlRouterProvider) {
            //$urlRouterProvider.otherwise('/angularjs');
            $stateProvider
                .state('angularjs', {
                    url: '/angularjs',
                    templateUrl: 'content/views/ready_angularjs.html'
                })
                .state('dashboard', {
                    parent: 'admin',
                    url: '/dashboard',
                    controller: 'DashboardCtrl',
                    views: {
                        'content@': {
                            templateUrl: 'content/views/ready_dashboard.html',
                            controller: 'DashboardCtrl',
                            controllerAs: 'vm'
                        }
                    },
                    data: {
                        authorities: ['ROLE_USER'],
                        pageTitle: 'dashboard.title'
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dashboard');
                            return $translate.refresh();
                        }],
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/slick/slick.min.css',
                                    'content/js/plugins/slick/slick-theme.min.css',
                                    'content/js/plugins/slick/slick.min.js',
                                    'content/js/plugins/chartjs/Chart.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('uiActivity', {
                    url: '/ui/activity',
                    templateUrl: 'content/views/ui_activity.html',
                    controller: 'UiActivityCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/sweetalert2/sweetalert2.min.css',
                                    'content/js/plugins/bootstrap-notify/bootstrap-notify.min.js',
                                    'content/js/plugins/sweetalert2/sweetalert2.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('uiTabs', {
                    url: '/ui/tabs',
                    templateUrl: 'content/views/ui_tabs.html'
                })
                .state('uiModalsTooltips', {
                    url: '/ui/modals-tooltips',
                    templateUrl: 'content/views/ui_modals_tooltips.html'
                })
                .state('uiColorThemes', {
                    url: '/ui/color-themes',
                    templateUrl: 'content/views/ui_color_themes.html'
                })
                .state('uiBlocksDraggable', {
                    url: '/ui/blocks-draggable',
                    templateUrl: 'content/views/ui_blocks_draggable.html',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/jquery-ui/jquery-ui.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('uiChatFull', {
                    url: '/ui/chat/full',
                    templateUrl: 'content/views/ui_chat_full.html',
                    controller: 'UiChatCtrl'
                })
                .state('uiChatFixed', {
                    url: '/ui/chat/fixed',
                    templateUrl: 'content/views/ui_chat_fixed.html',
                    controller: 'UiChatCtrl'
                })
                .state('uiChatPopup', {
                    url: '/ui/chat/popup',
                    templateUrl: 'content/views/ui_chat_popup.html',
                    controller: 'UiChatCtrl'
                })
                .state('tablesTools', {
                    url: '/tables/tools',
                    templateUrl: 'content/views/tables_tools.html'
                })
                .state('tablesDatatables', {
                    url: '/tables/datatables',
                    templateUrl: 'content/views/tables_datatables.html',
                    controller: 'TablesDatatablesCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/datatables/jquery.dataTables.min.css',
                                    'content/js/plugins/datatables/jquery.dataTables.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('formsPickersMore', {
                    url: '/forms/pickers-more',
                    templateUrl: 'content/views/forms_pickers_more.html',
                    controller: 'FormsPickersMoreCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/bootstrap-datepicker/bootstrap-datepicker3.min.css',
                                    'content/js/plugins/bootstrap-datetimepicker/bootstrap-datetimepicker.min.css',
                                    'content/js/plugins/bootstrap-colorpicker/css/bootstrap-colorpicker.min.css',
                                    'content/js/plugins/select2/select2.min.css',
                                    'content/js/plugins/select2/select2-bootstrap.min.css',
                                    'content/js/plugins/jquery-auto-complete/jquery.auto-complete.min.css',
                                    'content/js/plugins/ion-rangeslider/css/ion.rangeSlider.min.css',
                                    'content/js/plugins/ion-rangeslider/css/ion.rangeSlider.skinHTML5.min.css',
                                    'content/js/plugins/dropzonejs/dropzone.min.css',
                                    'content/js/plugins/jquery-tags-input/jquery.tagsinput.min.css',
                                    'content/js/plugins/bootstrap-datepicker/bootstrap-datepicker.min.js',
                                    'content/js/plugins/bootstrap-datetimepicker/moment.min.js',
                                    'content/js/plugins/bootstrap-datetimepicker/bootstrap-datetimepicker.min.js',
                                    'content/js/plugins/bootstrap-colorpicker/bootstrap-colorpicker.min.js',
                                    'content/js/plugins/bootstrap-maxlength/bootstrap-maxlength.min.js',
                                    'content/js/plugins/select2/select2.full.min.js',
                                    'content/js/plugins/masked-inputs/jquery.maskedinput.min.js',
                                    'content/js/plugins/jquery-auto-complete/jquery.auto-complete.min.js',
                                    'content/js/plugins/ion-rangeslider/js/ion.rangeSlider.min.js',
                                    'content/js/plugins/dropzonejs/dropzone.min.js',
                                    'content/js/plugins/jquery-tags-input/jquery.tagsinput.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('formsEditors', {
                    url: '/forms/editors',
                    templateUrl: 'content/views/forms_editors.html',
                    controller: 'FormsEditorsCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/summernote/summernote.min.css',
                                    'content/js/plugins/summernote/summernote-bs3.min.css',
                                    'content/js/plugins/summernote/summernote.min.js',
                                    'content/js/plugins/ckeditor/ckeditor.js'
                                ]
                            });
                        }]
                    }
                })
                .state('formsValidation', {
                    url: '/forms/validation',
                    templateUrl: 'content/views/forms_validation.html',
                    controller: 'FormsValidationCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/select2/select2.min.css',
                                    'content/js/plugins/select2/select2-bootstrap.min.css',
                                    'content/js/plugins/select2/select2.full.min.js',
                                    'content/js/plugins/jquery-validation/jquery.validate.min.js',
                                    'content/js/plugins/jquery-validation/additional-methods.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('formsWizard', {
                    url: '/forms/wizard',
                    templateUrl: 'content/views/forms_wizard.html',
                    controller: 'FormsWizardCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/bootstrap-wizard/jquery.bootstrap.wizard.min.js',
                                    'content/js/plugins/jquery-validation/jquery.validate.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compCharts', {
                    url: '/components/charts',
                    templateUrl: 'content/views/comp_charts.html',
                    controller: 'CompChartsCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/sparkline/jquery.sparkline.min.js',
                                    'content/js/plugins/easy-pie-chart/jquery.easypiechart.min.js',
                                    'content/js/plugins/chartjs/Chart.min.js',
                                    'content/js/plugins/flot/jquery.flot.min.js',
                                    'content/js/plugins/flot/jquery.flot.pie.min.js',
                                    'content/js/plugins/flot/jquery.flot.stack.min.js',
                                    'content/js/plugins/flot/jquery.flot.resize.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compCalendar', {
                    url: '/components/calendar',
                    templateUrl: 'content/views/comp_calendar.html',
                    controller: 'CompCalendarCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/fullcalendar/fullcalendar.min.css',
                                    'content/js/plugins/jquery-ui/jquery-ui.min.js',
                                    'content/js/plugins/fullcalendar/moment.min.js',
                                    'content/js/plugins/fullcalendar/fullcalendar.min.js',
                                    'content/js/plugins/fullcalendar/gcal.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compSliders', {
                    url: '/components/sliders',
                    templateUrl: 'content/views/comp_sliders.html',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/slick/slick.min.css',
                                    'content/js/plugins/slick/slick-theme.min.css',
                                    'content/js/plugins/slick/slick.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compScrolling', {
                    url: '/components/scrolling',
                    templateUrl: 'content/views/comp_scrolling.html'
                })
                .state('compSyntaxHighlighting', {
                    url: '/components/syntax-highlighting',
                    templateUrl: 'content/views/comp_syntax_highlighting.html',
                    controller: 'CompSyntaxHighlightingCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/highlightjs/github-gist.min.css',
                                    'content/js/plugins/highlightjs/highlight.pack.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compRating', {
                    url: '/components/rating',
                    templateUrl: 'content/views/comp_rating.html',
                    controller: 'CompRatingCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/jquery-raty/jquery.raty.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compTreeview', {
                    url: '/components/treeview',
                    templateUrl: 'content/views/comp_treeview.html',
                    controller: 'CompTreeviewCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/bootstrap-treeview/bootstrap-treeview.min.css',
                                    'content/js/plugins/bootstrap-treeview/bootstrap-treeview.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compMapsGoogle', {
                    url: '/components/maps/google',
                    templateUrl: 'content/views/comp_maps.html',
                    controller: 'CompMapsGoogleCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    { type: 'js', path: '//maps.google.com/maps/api/js' },
                                    'content/js/plugins/gmapsjs/gmaps.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compMapsGoogleFull', {
                    url: '/components/maps/google-full',
                    templateUrl: 'content/views/comp_maps_full.html',
                    controller: 'CompMapsGoogleFullCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    { type: 'js', path: '//maps.google.com/maps/api/js' },
                                    'content/js/plugins/gmapsjs/gmaps.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compMapsVector', {
                    url: '/components/maps/vector',
                    templateUrl: 'content/views/comp_maps_vector.html',
                    controller: 'CompMapsVectorCtrl',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/jquery-jvectormap/jquery-jvectormap.min.css',
                                    'content/js/plugins/jquery-jvectormap/jquery-jvectormap.min.js',
                                    'content/js/plugins/jquery-jvectormap/maps/jquery-jvectormap-au-mill-en.js',
                                    'content/js/plugins/jquery-jvectormap/maps/jquery-jvectormap-cn-mill-en.js',
                                    'content/js/plugins/jquery-jvectormap/maps/jquery-jvectormap-de-mill-en.js',
                                    'content/js/plugins/jquery-jvectormap/maps/jquery-jvectormap-europe-mill-en.js',
                                    'content/js/plugins/jquery-jvectormap/maps/jquery-jvectormap-fr-mill-en.js',
                                    'content/js/plugins/jquery-jvectormap/maps/jquery-jvectormap-in-mill-en.js',
                                    'content/js/plugins/jquery-jvectormap/maps/jquery-jvectormap-us-aea-en.js',
                                    'content/js/plugins/jquery-jvectormap/maps/jquery-jvectormap-world-mill-en.js',
                                    'content/js/plugins/jquery-jvectormap/maps/jquery-jvectormap-za-mill-en.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compGallerySimple', {
                    url: '/components/gallery/simple',
                    templateUrl: 'content/views/comp_gallery_simple.html',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/magnific-popup/magnific-popup.min.css',
                                    'content/js/plugins/magnific-popup/magnific-popup.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('compGalleryAdvanced', {
                    url: '/components/gallery/advanced',
                    templateUrl: 'content/views/comp_gallery_advanced.html',
                    resolve: {
                        deps: ['$ocLazyLoad', function($ocLazyLoad) {
                            return $ocLazyLoad.load({
                                insertBefore: '#css-bootstrap',
                                serie: true,
                                files: [
                                    'content/js/plugins/magnific-popup/magnific-popup.min.css',
                                    'content/js/plugins/magnific-popup/magnific-popup.min.js'
                                ]
                            });
                        }]
                    }
                })
                .state('blocks', {
                    url: '/blocks',
                    templateUrl: 'content/views/api_blocks.html'
                })
                .state('layout', {
                    url: '/layout',
                    templateUrl: 'content/views/api_layout.html'
                })
                .state('create', {
                    url: '/create',
                    templateUrl: 'content/views/ready_create.html'
                });
        }
    ]);

// Tooltips and Popovers configuration
    App.config(['$uibTooltipProvider',
        function ($uibTooltipProvider) {
            $uibTooltipProvider.options({
                appendToBody: true
            });
        }
    ]);

// Custom UI helper functions
    App.factory('uiHelpers', function () {
        return {
            // Handles active color theme
            uiHandleColorTheme: function (themeName) {
                var colorTheme = jQuery('#css-theme');

                if (themeName) {
                    if (colorTheme.length && (colorTheme.prop('href') !== 'content/css/themes/' + themeName + '.min.css')) {
                        jQuery('#css-theme').prop('href', 'content/css/themes/' + themeName + '.min.css');
                    } else if (!colorTheme.length) {
                        jQuery('#css-main').after('<link rel="stylesheet" id="css-theme" href="content/css/themes/' + themeName + '.min.css">');
                    }
                } else {
                    if (colorTheme.length) {
                        colorTheme.remove();
                    }
                }
            },
            // Handles #main-container height resize to push footer to the bottom of the page
            uiHandleMain: function () {
                var lMain       = jQuery('#main-container');
                var hWindow     = jQuery(window).height();
                var hHeader     = jQuery('#header-navbar').outerHeight();
                var hFooter     = jQuery('#page-footer').outerHeight();

                if (jQuery('#page-container').hasClass('header-navbar-fixed')) {
                    lMain.css('min-height', hWindow - hFooter);
                } else {
                    lMain.css('min-height', hWindow - (hHeader + hFooter));
                }
            },
            // Handles transparent header functionality (solid on scroll - used in frontend pages)
            uiHandleHeader: function () {
                var lPage = jQuery('#page-container');

                if (lPage.hasClass('header-navbar-fixed') && lPage.hasClass('header-navbar-transparent')) {
                    jQuery(window).on('scroll', function(){
                        if (jQuery(this).scrollTop() > 20) {
                            lPage.addClass('header-navbar-scroll');
                        } else {
                            lPage.removeClass('header-navbar-scroll');
                        }
                    });
                }
            },
            // Handles sidebar and side overlay custom scrolling functionality
            uiHandleScroll: function(mode) {
                var windowW            = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                var lPage              = jQuery('#page-container');
                var lSidebar           = jQuery('#sidebar');
                var lSidebarScroll     = jQuery('#sidebar-scroll');
                var lSideOverlay       = jQuery('#side-overlay');
                var lSideOverlayScroll = jQuery('#side-overlay-scroll');

                // Init scrolling
                if (mode === 'init') {
                    // Init scrolling only if required the first time
                    uiHandleScroll();
                } else {
                    // If screen width is greater than 991 pixels and .side-scroll is added to #page-container
                    if (windowW > 991 && lPage.hasClass('side-scroll') && (lSidebar.length || lSideOverlay.length)) {
                        // If sidebar exists
                        if (lSidebar.length) {
                            // Turn sidebar's scroll lock off (slimScroll will take care of it)
                            jQuery(lSidebar).scrollLock('disable');

                            // If sidebar scrolling does not exist init it..
                            if (lSidebarScroll.length && (!lSidebarScroll.parent('.slimScrollDiv').length)) {
                                lSidebarScroll.slimScroll({
                                    height: lSidebar.outerHeight(),
                                    color: '#fff',
                                    size: '5px',
                                    opacity : .35,
                                    wheelStep : 15,
                                    distance : '2px',
                                    railVisible: false,
                                    railOpacity: 1
                                });
                            }
                            else { // ..else resize scrolling height
                                lSidebarScroll
                                    .add(lSidebarScroll.parent())
                                    .css('height', lSidebar.outerHeight());
                            }
                        }

                        // If side overlay exists
                        if (lSideOverlay.length) {
                            // Turn side overlay's scroll lock off (slimScroll will take care of it)
                            jQuery(lSideOverlay).scrollLock('disable');

                            // If side overlay scrolling does not exist init it..
                            if (lSideOverlayScroll.length && (!lSideOverlayScroll.parent('.slimScrollDiv').length)) {
                                lSideOverlayScroll.slimScroll({
                                    height: lSideOverlay.outerHeight(),
                                    color: '#000',
                                    size: '5px',
                                    opacity : .35,
                                    wheelStep : 15,
                                    distance : '2px',
                                    railVisible: false,
                                    railOpacity: 1
                                });
                            }
                            else { // ..else resize scrolling height
                                lSideOverlayScroll
                                    .add(lSideOverlayScroll.parent())
                                    .css('height', lSideOverlay.outerHeight());
                            }
                        }
                    } else {
                        // If sidebar exists
                        if (lSidebar.length) {
                            // If sidebar scrolling exists destroy it..
                            if (lSidebarScroll.length && lSidebarScroll.parent('.slimScrollDiv').length) {
                                lSidebarScroll
                                    .slimScroll({destroy: true});
                                lSidebarScroll
                                    .attr('style', '');
                            }

                            // Turn sidebars's scroll lock on
                            jQuery(lSidebar).scrollLock();
                        }

                        // If side overlay exists
                        if (lSideOverlay.length) {
                            // If side overlay scrolling exists destroy it..
                            if (lSideOverlayScroll.length && lSideOverlayScroll.parent('.slimScrollDiv').length) {
                                lSideOverlayScroll
                                    .slimScroll({destroy: true});
                                lSideOverlayScroll
                                    .attr('style', '');
                            }

                            // Turn side overlay's scroll lock on
                            jQuery(lSideOverlay).scrollLock();
                        }
                    }
                }
            },
            // Handles page loader functionality
            uiLoader: function (mode) {
                var lBody       = jQuery('body');
                var lpageLoader = jQuery('#page-loader');

                if (mode === 'show') {
                    if (lpageLoader.length) {
                        lpageLoader.fadeIn(250);
                    } else {
                        lBody.prepend('<div id="page-loader"></div>');
                    }
                } else if (mode === 'hide') {
                    if (lpageLoader.length) {
                        lpageLoader.fadeOut(250);
                    }
                }
            },
            // Handles blocks API functionality
            uiBlocks: function (block, mode, button) {
                // Set default icons for fullscreen and content toggle buttons
                var iconFullscreen         = 'si si-size-fullscreen';
                var iconFullscreenActive   = 'si si-size-actual';
                var iconContent            = 'si si-arrow-up';
                var iconContentActive      = 'si si-arrow-down';

                if (mode === 'init') {
                    // Auto add the default toggle icons
                    switch(button.data('action')) {
                        case 'fullscreen_toggle':
                            button.html('<i class="' + (button.closest('.block').hasClass('block-opt-fullscreen') ? iconFullscreenActive : iconFullscreen) + '"></i>');
                            break;
                        case 'content_toggle':
                            button.html('<i class="' + (button.closest('.block').hasClass('block-opt-hidden') ? iconContentActive : iconContent) + '"></i>');
                            break;
                        default:
                            return false;
                    }
                } else {
                    // Get block element
                    var elBlock = (block instanceof jQuery) ? block : jQuery(block);

                    // If element exists, procceed with blocks functionality
                    if (elBlock.length) {
                        // Get block option buttons if exist (need them to update their icons)
                        var btnFullscreen  = jQuery('[data-js-block-option][data-action="fullscreen_toggle"]', elBlock);
                        var btnToggle      = jQuery('[data-js-block-option][data-action="content_toggle"]', elBlock);

                        // Mode selection
                        switch(mode) {
                            case 'fullscreen_toggle':
                                elBlock.toggleClass('block-opt-fullscreen');

                                // Enable/disable scroll lock to block
                                elBlock.hasClass('block-opt-fullscreen') ? jQuery(elBlock).scrollLock() : jQuery(elBlock).scrollLock('off');

                                // Update block option icon
                                if (btnFullscreen.length) {
                                    if (elBlock.hasClass('block-opt-fullscreen')) {
                                        jQuery('i', btnFullscreen)
                                            .removeClass(iconFullscreen)
                                            .addClass(iconFullscreenActive);
                                    } else {
                                        jQuery('i', btnFullscreen)
                                            .removeClass(iconFullscreenActive)
                                            .addClass(iconFullscreen);
                                    }
                                }
                                break;
                            case 'fullscreen_on':
                                elBlock.addClass('block-opt-fullscreen');

                                // Enable scroll lock to block
                                jQuery(elBlock).scrollLock();

                                // Update block option icon
                                if (btnFullscreen.length) {
                                    jQuery('i', btnFullscreen)
                                        .removeClass(iconFullscreen)
                                        .addClass(iconFullscreenActive);
                                }
                                break;
                            case 'fullscreen_off':
                                elBlock.removeClass('block-opt-fullscreen');

                                // Disable scroll lock to block
                                jQuery(elBlock).scrollLock('off');

                                // Update block option icon
                                if (btnFullscreen.length) {
                                    jQuery('i', btnFullscreen)
                                        .removeClass(iconFullscreenActive)
                                        .addClass(iconFullscreen);
                                }
                                break;
                            case 'content_toggle':
                                elBlock.toggleClass('block-opt-hidden');

                                // Update block option icon
                                if (btnToggle.length) {
                                    if (elBlock.hasClass('block-opt-hidden')) {
                                        jQuery('i', btnToggle)
                                            .removeClass(iconContent)
                                            .addClass(iconContentActive);
                                    } else {
                                        jQuery('i', btnToggle)
                                            .removeClass(iconContentActive)
                                            .addClass(iconContent);
                                    }
                                }
                                break;
                            case 'content_hide':
                                elBlock.addClass('block-opt-hidden');

                                // Update block option icon
                                if (btnToggle.length) {
                                    jQuery('i', btnToggle)
                                        .removeClass(iconContent)
                                        .addClass(iconContentActive);
                                }
                                break;
                            case 'content_show':
                                elBlock.removeClass('block-opt-hidden');

                                // Update block option icon
                                if (btnToggle.length) {
                                    jQuery('i', btnToggle)
                                        .removeClass(iconContentActive)
                                        .addClass(iconContent);
                                }
                                break;
                            case 'refresh_toggle':
                                elBlock.toggleClass('block-opt-refresh');

                                // Return block to normal state if the demostration mode is on in the refresh option button - data-action-mode="demo"
                                if (jQuery('[data-js-block-option][data-action="refresh_toggle"][data-action-mode="demo"]', elBlock).length) {
                                    setTimeout(function(){
                                        elBlock.removeClass('block-opt-refresh');
                                    }, 2000);
                                }
                                break;
                            case 'state_loading':
                                elBlock.addClass('block-opt-refresh');
                                break;
                            case 'state_normal':
                                elBlock.removeClass('block-opt-refresh');
                                break;
                            case 'close':
                                elBlock.hide();
                                break;
                            case 'open':
                                elBlock.show();
                                break;
                            default:
                                return false;
                        }
                    }
                }
            }
        };
    });

// Run our App
    /*
     App.run(function($rootScope, uiHelpers) {
     // Access uiHelpers easily from all controllers
     $rootScope.helpers = uiHelpers;

     // On window resize or orientation change resize #main-container & Handle scrolling
     var resizeTimeout;

     jQuery(window).on('resize orientationchange', function () {
     clearTimeout(resizeTimeout);

     resizeTimeout = setTimeout(function(){
     $rootScope.helpers.uiHandleScroll();
     $rootScope.helpers.uiHandleMain();
     }, 150);
     });
     });
     */

// Application Main Controller
    App.controller('AppCtrl', ['$scope', '$localStorage', '$window', 'Principal',
        function ($scope, $localStorage, $window, Principal) {
            $scope.vm = this;
            this.isAuthenticated = Principal.isAuthenticated;

            // Template Settings
            $scope.oneui = {
                version: '2.0', // Template version
                localStorage: false, // Enable/Disable local storage
                settings: {
                    activeColorTheme: false, // Set a color theme of your choice, available: 'amethyst', 'city, 'flat', 'modern' and 'smooth'
                    sidebarLeft: true, // true: Left Sidebar and right Side Overlay, false: Right Sidebar and left Side Overlay
                    sidebarOpen: true, // Visible Sidebar by default (> 991px)
                    sidebarOpenXs: false, // Visible Sidebar by default (< 992px)
                    sidebarMini: false, // Mini hoverable Sidebar (> 991px)
                    sideOverlayOpen: false, // Visible Side Overlay by default (> 991px)
                    sideOverlayHover: false, // Hoverable Side Overlay (> 991px)
                    sideScroll: true, // Enables custom scrolling on Sidebar and Side Overlay instead of native scrolling (> 991px)
                    headerFixed: true // Enables fixed header
                }
            };

            // If local storage setting is enabled
            if ($scope.oneui.localStorage) {
                // Save/Restore local storage settings
                if ($scope.oneui.localStorage) {
                    if (angular.isDefined($localStorage.oneuiSettings)) {
                        $scope.oneui.settings = $localStorage.oneuiSettings;
                    } else {
                        $localStorage.oneuiSettings = $scope.oneui.settings;
                    }
                }

                // Watch for settings changes
                $scope.$watch('oneui.settings', function () {
                    // If settings are changed then save them to localstorage
                    $localStorage.oneuiSettings = $scope.oneui.settings;
                }, true);
            }

            // Watch for activeColorTheme variable update
            $scope.$watch('oneui.settings.activeColorTheme', function () {
                // Handle Color Theme
                $scope.helpers.uiHandleColorTheme($scope.oneui.settings.activeColorTheme);
            }, true);

            // Watch for sideScroll variable update
            $scope.$watch('oneui.settings.sideScroll', function () {
                // Handle Scrolling
                setTimeout(function () {
                    $scope.helpers.uiHandleScroll();
                }, 150);
            }, true);

            // When view content is loaded
            $scope.$on('$viewContentLoaded', function () {
                // Hide page loader
                $scope.helpers.uiLoader('hide');

                // Resize #main-container
                $scope.helpers.uiHandleMain();
            });
        }
    ]);


    /*
     * Partial views controllers
     *
     */

// Side Overlay Controller
    App.controller('SideOverlayCtrl', ['$scope', '$localStorage', '$window',
        function ($scope, $localStorage, $window) {
            // When view content is loaded
            $scope.$on('$includeContentLoaded', function () {
                // Handle Scrolling
                $scope.helpers.uiHandleScroll();
            });
        }
    ]);

// Sidebar Controller
    App.controller('SidebarCtrl', ['$scope', '$localStorage', '$window', '$location',
        function ($scope, $localStorage, $window, $location) {
            // When view content is loaded
            $scope.$on('$includeContentLoaded', function () {
                // Handle Scrolling
                $scope.helpers.uiHandleScroll();

                // Get current path to use it for adding active classes to our submenus
                $scope.path = $location.path();
            });
        }
    ]);

// Header Controller
    App.controller('HeaderCtrl', ['$scope', '$localStorage', '$window',
        function ($scope, $localStorage, $window) {
            // When view content is loaded
            $scope.$on('$includeContentLoaded', function () {
                // Transparent header functionality
                $scope.helpers.uiHandleHeader();
            });
        }
    ]);


    //http://stackoverflow.com/questions/23324545/angularjs-ng-style-does-not-update-css-background-property
    App.directive('backgroundImage', function(){
        return function(scope, element, attrs){
            attrs.$observe('backgroundImage', function(value) {
                element.css({
                    'background': 'transparent url(' + value +') center center no-repeat'
                });
            });
        };
    });

    App.directive('aDisabled', function() {
        return {
            compile: function(tElement, tAttrs, transclude) {
                //Disable ngClick
                tAttrs["ngClick"] = "!("+tAttrs["aDisabled"]+") && ("+tAttrs["ngClick"]+")";

                //return a link function
                return function (scope, iElement, iAttrs) {

                    //Toggle "disabled" to class when aDisabled becomes true
                    scope.$watch(iAttrs["aDisabled"], function(newValue) {
                        if (newValue !== undefined) {
                            iElement.toggleClass("disabled", newValue);
                        }
                    });

                    //Disable href on click
                    iElement.on("click", function(e) {
                        if (scope.$eval(iAttrs["aDisabled"])) {
                            e.preventDefault();
                        }
                    });
                };
            }
        };
    });

    /*
     * Custom helper directives
     *
     */

// View loader functionality
// By adding the attribute 'data-js-view-loader'
    App.directive('jsViewLoader', function () {
        return {
            link: function (scope, element) {
                var el = jQuery(element);

                // Hide the view loader, populate it with content and style it
                el
                    .hide()
                    .html('<i class="fa-fw fa fa-refresh fa-spin text-primary"></i>')
                    .css({
                        'position': 'fixed',
                        'top': '20px',
                        'left': '50%',
                        'height': '20px',
                        'width': '20px',
                        'margin-left': '-10px',
                        'z-index': 99999
                    });

                // On state change start event, show the element
                scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                    el.fadeIn(250);
                });

                // On state change success event, hide the element
                scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
                    el.fadeOut(250);
                });
            }
        };
    });

// Main navigation functionality
// By adding the attribute 'data-js-main-nav'
    App.directive('jsMainNav', function () {
        return {
            link: function (scope, element) {
                // When a submenu link is clicked
                jQuery('[data-toggle="nav-submenu"]', element).on('click', function(e){
                    // Get link
                    var link = jQuery(this);

                    // Get link's parent
                    var parentLi = link.parent('li');

                    if (parentLi.hasClass('open')) { // If submenu is open, close it..
                        parentLi.removeClass('open');
                    } else { // .. else if submenu is closed, close all other (same level) submenus first before open it
                        link
                            .closest('ul')
                            .find('> li')
                            .removeClass('open');

                        parentLi
                            .addClass('open');
                    }

                    return false;
                });

                // Remove focus when clicking on a link
                jQuery('a', element).on('click', function(){
                    jQuery(this).blur();
                });

                // On state change success event, hide the sidebar in mobile devices
                scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
                    scope.oneui.settings.sidebarOpenXs = false;
                });
            }
        };
    });

// Form helper functionality (placeholder support for IE9 which uses HTML5 Placeholder plugin + Material forms)
// Auto applied to all your form elements (<form>)
    App.directive('form', function () {
        return {
            restrict: 'E',
            link: function (scope, element) {
                // Init form placeholder (for IE9)
                jQuery('.form-control', element).placeholder();

                // Init material forms
                jQuery('.form-material.floating > .form-control', element).each(function(){
                    var input  = jQuery(this);
                    var parent = input.parent('.form-material');

                    if (input.val()) {
                        parent.addClass('open');
                    }

                    input.on('change', function(){
                        if (input.val()) {
                            parent.addClass('open');
                        } else {
                            parent.removeClass('open');
                        }
                    });
                });
            }
        };
    });

// Blocks options functionality
// By adding the attribute 'data-js-block-option'
    App.directive('jsBlockOption', function () {
        return {
            link: function (scope, element) {
                var el = jQuery(element);

                // Init Icons
                scope.helpers.uiBlocks(false, 'init', el);

                // Call blocks API on click
                el.on('click', function(){
                    scope.helpers.uiBlocks(el.closest('.block'), el.data('action'));
                });
            }
        };
    });

    App.directive('jsBlockAction', function () {
        return {
            link: function (scope, element) {
                var el = jQuery(element);

                // Call blocks API on click
                el.on('click', function(){
                    scope.helpers.uiBlocks(el.closest('.block'), el.data('action'));
                });
            }
        };
    });

// Print page on click
// By adding the attribute 'data-js-print'
    App.directive('jsPrint', function () {
        return {
            link: function(scope, element) {
                jQuery(element).on('click', function () {
                    // Store all #page-container classes
                    var lPage   = jQuery('#page-container');
                    var pageCls = lPage.prop('class');

                    // Remove all classes from #page-container
                    lPage.prop('class', '');

                    // Print the page
                    window.print();

                    // Restore all #page-container classes
                    lPage.prop('class', pageCls);
                });
            }
        };
    });

// Populate element's content with the correct copyright year
// By adding the attribute 'data-js-year-copy'
    App.directive('jsYearCopy', function () {
        return {
            link: function (scope, element) {
                var gdate     = new Date();
                var copyright = '2015';

                if (gdate.getFullYear() !== 2015) {
                    copyright = copyright + '-' + gdate.getFullYear().toString().substr(2,2);
                }

                element.text(copyright);
            }
        };
    });

// Animated scroll to an element
// By adding the attribute (with custom values) 'data-js-scroll-to="{target: '#target_element_id', speed: 'milliseconds'}"' to a button or a link
    App.directive('jsScrollTo', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsScrollTo) !== 'undefined') ? scope.$eval(attrs.jsScrollTo) : new Object();

                jQuery(element).on('click', function () {
                    jQuery('html, body').animate({
                        scrollTop: jQuery(options.target).offset().top
                    }, options.speed ? options.speed : 1000);
                });
            }
        };
    });

// Toggle a class to a target element
// By adding the attribute (with custom values) 'data-js-toggle-class="{target: '#target_element_id', class: 'class_name_to_toggle'}'
    App.directive('jsToggleClass', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsToggleClass) !== 'undefined') ? scope.$eval(attrs.jsToggleClass) : new Object();

                jQuery(element).on('click', function () {
                    jQuery(options.target).toggleClass(options.class);
                });
            }
        };
    });

// Removes focus from an element on click
// By adding the attribute 'data-js-blur'
    App.directive('jsBlur', function () {
        return {
            link: function (scope, element) {
                element.bind('click', function (){
                    element.blur();
                });
            }
        };
    });


    /*
     * Third party jQuery plugin inits or custom ui helpers packed in Angular directives for easy
     *
     */

// Bootstrap Tabs (legacy init - if you like, you can use the native implementation from Angular UI Bootstrap)
// By adding the attribute 'data-js-tabs' to a ul with Bootstrap tabs markup
    App.directive('jsTabs', function () {
        return {
            link: function (scope, element) {
                jQuery('a', element).on('click', function (e) {
                    e.preventDefault();
                    jQuery(this).tab('show');
                });
            }
        };
    });

// Custom Table functionality: Section toggling
// By adding the attribute 'data-js-table-sections' to your table
    App.directive('jsTableSections', function () {
        return {
            link: function (scope, element) {
                var table      = jQuery(element);
                var tableRows  = jQuery('.js-table-sections-header > tr', table);

                tableRows.on('click', function(e) {
                    var row    = jQuery(this);
                    var tbody  = row.parent('tbody');

                    if (! tbody.hasClass('open')) {
                        jQuery('tbody', table).removeClass('open');
                    }

                    tbody.toggleClass('open');
                });
            }
        };
    });

// Custom Table functionality: Checkable rows
// By adding the attribute 'data-js-table-checkable' to your table
    App.directive('jsTableCheckable', function () {
        return {
            link: function (scope, element) {
                var table = jQuery(element);

                // When a checkbox is clicked in thead
                jQuery('thead input:checkbox', table).click(function() {
                    var checkedStatus = jQuery(this).prop('checked');

                    // Check or uncheck all checkboxes in tbody
                    jQuery('tbody input:checkbox', table).each(function() {
                        var checkbox = jQuery(this);

                        checkbox.prop('checked', checkedStatus);
                        uiCheckRow(checkbox, checkedStatus);
                    });
                });

                // When a checkbox is clicked in tbody
                jQuery('tbody input:checkbox', table).click(function() {
                    var checkbox = jQuery(this);

                    uiCheckRow(checkbox, checkbox.prop('checked'));
                });

                // When a row is clicked in tbody
                jQuery('tbody > tr', table).click(function(e) {
                    if (e.target.type !== 'checkbox'
                        && e.target.type !== 'button'
                        && e.target.tagName.toLowerCase() !== 'a'
                        && !jQuery(e.target).parent('label').length) {
                        var checkbox       = jQuery('input:checkbox', this);
                        var checkedStatus  = checkbox.prop('checked');

                        checkbox.prop('checked', ! checkedStatus);
                        uiCheckRow(checkbox, ! checkedStatus);
                    }
                });

                // Checkable table functionality helper - Checks or unchecks table row
                var uiCheckRow = function(checkbox, checkedStatus) {
                    if (checkedStatus) {
                        checkbox
                            .closest('tr')
                            .addClass('active');
                    } else {
                        checkbox
                            .closest('tr')
                            .removeClass('active');
                    }
                };
            }
        };
    });

// jQuery Appear, for more examples you can check out https://github.com/bas2k/jquery.appear
// By adding the attribute (with custom values) 'data-js-appear="{speed: 1000, refreshInterval: 10, ...}'
    App.directive('jsAppear', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsAppear) !== 'undefined') ? scope.$eval(attrs.jsAppear) : new Object();
                var el       = jQuery(element);
                var windowW  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

                el.bind('appear',function() {
                    setTimeout(function(){
                        el.removeClass('visibility-hidden')
                            .addClass(options.class ? options.class : 'animated fadeIn');
                    }, (jQuery('html').hasClass('ie9') || windowW < 992) ? 0 : (options.timeout ? options.timeout : 0));
                });
            }
        };
    });

// jQuery Appear + jQuery countTo, for more examples you can check out https://github.com/bas2k/jquery.appear and https://github.com/mhuggins/jquery-countTo
// By adding the attribute (with custom values) 'data-js-count-to="{speed: 1000, refreshInterval: 10, ...}'
    App.directive('jsCountTo', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsCountTo) !== 'undefined') ? scope.$eval(attrs.jsCountTo) : new Object();
                var el      = jQuery(element);

                el.bind('appear',function() {
                    el.countTo({
                        speed: options.speed ? options.speed : 1500,
                        refreshInterval: options.refreshInterval ? options.refreshInterval : 15,
                        onComplete: function() {
                            if(options.after) {
                                el.html(el.html() + options.after);
                            }
                        }
                    });
                });
            }
        };
    });

// SlimScroll, for more examples you can check out http://rocha.la/jQuery-slimScroll
// By adding the attribute (with custom values) 'data-js-slimscroll="{height: '100px', size: '3px', ...}'
    App.directive('jsSlimscroll', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsSlimscroll) !== 'undefined') ? scope.$eval(attrs.jsSlimscroll) : new Object();

                jQuery(element).slimScroll({
                    height: options.height ? options.height : '200px',
                    size: options.size ? options.size : '5px',
                    position: options.position ? options.position : 'right',
                    color: options.color ? options.color : '#000',
                    alwaysVisible: options.alwaysVisible ? true : false,
                    railVisible: options.railVisible ? true : false,
                    railColor: options.railColor ? options.railColor : '#999',
                    railOpacity: options.railOpacity ? options.railOpacity : .3
                });
            }
        };
    });

    /*
     ********************************************************************************************
     *
     * All the following directives require each plugin's resources (JS, CSS) to be lazy loaded in
     * the page in order to work, so please make sure you've included them in your route configuration
     *
     ********************************************************************************************
     */

// Magnific Popup, for more examples you can check out http://dimsemenov.com/plugins/magnific-popup/
// By adding the attribute (with custom value) 'data-js-magnific="{advancedGallery: false}'
    App.directive('jsMagnific', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsMagnific) !== 'undefined') ? scope.$eval(attrs.jsMagnific) : new Object();

                jQuery(element).magnificPopup({
                    delegate: options.advancedGallery ? 'a.img-lightbox' : 'a.img-link',
                    type: 'image',
                    gallery: {
                        enabled: true
                    }
                });
            }
        };
    });

// Slick, for more examples you can check out http://kenwheeler.github.io/slick/
// By adding the attribute (with custom values) 'data-js-slider="{arrows: true, dots: true, ...}'
    App.directive('jsSlider', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsSlider) !== 'undefined') ? scope.$eval(attrs.jsSlider) : new Object();

                jQuery(element).slick({
                    arrows: options.arrows ? options.arrows : false,
                    dots: options.dots ? options.dots : false,
                    slidesToShow: options.slidesToShow ? options.arrows : 1,
                    autoplay: options.autoplay ? options.autoplay : false,
                    autoplaySpeed: options.autoplaySpeed ? options.autoplaySpeed : 3000
                });
            }
        };
    });

// Bootstrap Datepicker, for more examples you can check out https://github.com/eternicode/bootstrap-datepicker
// By adding the attribute 'data-js-datepicker'
    App.directive('jsDatepicker', function () {
        return {
            link: function (scope, element) {
                jQuery(element).datepicker({
                    weekStart: 1,
                    autoclose: true,
                    todayHighlight: true
                });
            }
        };
    });

// Bootstrap Colorpicker, for more examples you can check out http://mjolnic.com/bootstrap-colorpicker/
// By adding the attribute (with custom value) 'data-js-colorpicker="{format: 'hex', inline: true}'
    App.directive('jsColorpicker', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsColorpicker) !== 'undefined') ? scope.$eval(attrs.jsColorpicker) : new Object();

                jQuery(element).colorpicker({
                    format: options.format ? options.format : 'hex',
                    inline: options.inline ? true : false
                });
            }
        };
    });

// Masked Inputs, for more examples you can check out http://digitalbush.com/projects/masked-input-plugin/
// By adding the attribute (with custom value) 'data-js-masked-input="99/99/9999"'
    App.directive('jsMaskedInput', function () {
        return {
            link: function (scope, element, attrs) {
                jQuery(element).mask(attrs.jsMaskedInput);
            }
        };
    });

// Tags Inputs, for more examples you can check out https://github.com/xoxco/jQuery-Tags-Input
// By adding the attribute 'data-js-tags-input'
    App.directive('jsTagsInput', function () {
        return {
            link: function (scope, element) {
                jQuery(element).tagsInput({
                    height: '36px',
                    width: '100%',
                    defaultText: 'Add tag',
                    removeWithBackspace: true,
                    delimiter: [',']
                });
            }
        };
    });

// Bootstrap Notify, for more examples you can check out http://bootstrap-growl.remabledesigns.com/
// By adding the attribute (with custom values) 'data-js-notify="{icon: 'fa fa-check', message: 'Your message!', ... }'
    App.directive('jsNotify', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsNotify) !== 'undefined') ? scope.$eval(attrs.jsNotify) : new Object();

                jQuery(element).on('click', function(){
                    jQuery.notify({
                            icon: options.icon ? options.icon : '',
                            message: options.message,
                            url: options.url ? options.url : ''
                        },
                        {
                            element: 'body',
                            type: options.type ? options.type : 'info',
                            allow_dismiss: true,
                            newest_on_top: true,
                            showProgressbar: false,
                            placement: {
                                from: options.from ? options.from : 'top',
                                align: options.align ? options.align : 'right'
                            },
                            offset: 20,
                            spacing: 10,
                            z_index: 1033,
                            delay: 5000,
                            timer: 1000,
                            animate: {
                                enter: 'animated fadeIn',
                                exit: 'animated fadeOutDown'
                            }
                        });
                });
            }
        };
    });

// Draggable items with jQuery, for more examples you can check out https://jqueryui.com/sortable/
// By adding the attribute 'data-js-draggable-items'
    App.directive('jsDraggableItems', function () {
        return {
            link: function (scope, element) {
                jQuery('.draggable-column', element).sortable({
                    connectWith: '.draggable-column',
                    items: '.draggable-item',
                    dropOnEmpty: true,
                    opacity: .75,
                    handle: '.draggable-handler',
                    placeholder: 'draggable-placeholder',
                    tolerance: 'pointer',
                    start: function(e, ui){
                        ui.placeholder.css({
                            'height': ui.item.outerHeight(),
                            'margin-bottom': ui.item.css('margin-bottom')
                        });
                    }
                });
            }
        };
    });

// Easy Pie Chart, for more examples you can check out http://rendro.github.io/easy-pie-chart/
// By adding the attribute (with custom values) 'data-js-pie-chart="{barColor: '#000', trackColor: '#eee', ... }'
    App.directive('jsPieChart', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsPieChart) !== 'undefined') ? scope.$eval(attrs.jsPieChart) : new Object();

                jQuery(element).easyPieChart({
                    barColor: options.barColor ? options.barColor : '#777777',
                    trackColor: options.trackColor ? options.trackColor : '#eeeeee',
                    lineWidth: options.lineWidth ? options.lineWidth : 3,
                    size: options.size ? options.size : '80',
                    animate: options.animate ? options.animate : 750,
                    scaleColor: options.scaleColor ? options.scaleColor : false
                });
            }
        };
    });

// Bootstrap Maxlength, for more examples you can check out https://github.com/mimo84/bootstrap-maxlength
// By adding the attribute (with custom values) 'data-js-maxlength="{alwaysShow: 'true', threshold: '10', ... }'
    App.directive('jsMaxlength', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsMaxlength) !== 'undefined') ? scope.$eval(attrs.jsMaxlength) : new Object();

                jQuery(element).maxlength({
                    alwaysShow: options.alwaysShow ? true : false,
                    threshold: options.threshold ? options.threshold : 10,
                    warningClass: options.warningClass ? options.warningClass : 'label label-warning',
                    limitReachedClass: options.limitReachedClass ? options.limitReachedClass : 'label label-danger',
                    placement: options.placement ? options.placement : 'bottom',
                    preText: options.preText ? options.preText : '',
                    separator: options.separator ? options.separator : '/',
                    postText: options.postText ? options.postText : ''
                });
            }
        };
    });

// Bootstrap Datetimepicker, for more examples you can check out https://github.com/Eonasdan/bootstrap-datetimepicker
// By adding the attribute (with custom values) 'data-js-datetimepicker="{format: 'false', useCurrent: 'false', ... }'
    App.directive('jsDatetimepicker', function () {
        return {
            link: function (scope, element, attrs) {
                var options = (typeof scope.$eval(attrs.jsDatetimepicker) !== 'undefined') ? scope.$eval(attrs.jsDatetimepicker) : new Object();

                jQuery(element).datetimepicker({
                    format: options.format ? options.format : false,
                    useCurrent: options.useCurrent ? options.useCurrent : false,
                    locale: moment.locale('' + (options.locale ? options.locale : '') +''),
                    showTodayButton: options.showTodayButton ? options.showTodayButton : false,
                    showClear: options.showClear ? options.showClear : false,
                    showClose: options.showClose ? options.showClose : false,
                    sideBySide: options.sideBySide ? options.sideBySide : false,
                    inline: options.inline ? options.inline : false,
                    icons: {
                        time: 'si si-clock',
                        date: 'si si-calendar',
                        up: 'si si-arrow-up',
                        down: 'si si-arrow-down',
                        previous: 'si si-arrow-left',
                        next: 'si si-arrow-right',
                        today: 'si si-size-actual',
                        clear: 'si si-trash',
                        close: 'si si-close'
                    }
                });
            }
        };
    });

// Ion Range Slider, for more examples you can check out https://github.com/IonDen/ion.rangeSlider
// By adding the attribute 'data-js-range-slider'
    App.directive('jsRangeSlider', function () {
        return {
            link: function (scope, element) {
                jQuery(element).ionRangeSlider({
                    input_values_separator: ';'
                });
            }
        };
    });

// Dropzone, for more examples you can check out http://www.dropzonejs.com/#usage
// By adding the attribute 'data-js-dropzone' to your form
    App.directive('jsDropzone', function () {
        return {
            link: function(scope, element, attrs) {
                var config, dropzone;

                config = scope[attrs.dropzone];

                // create a Dropzone for the element with the given options
                config.dropzone = new Dropzone(element[0], config.options);

                // bind the given event handlers
                angular.forEach(config.eventHandlers, function (handler, event) {
                    config.dropzone.on(event, handler);
                });
            }
        }
    });

    App.filter('html', ['$sce',function($sce) {
        return function(val) {
            return $sce.trustAsHtml(val);
        };
    }]);

    App.filter("blankout", function() {
        return function(str) {
            return str ? str.replace(/./g, "X") : "";
        };
    });

    App.filter('csv', function() {
        return function(arr) {
            return arr ? arr.join(", ") : null;
        };
    });

    App.filter('propsFilter', function() {
        return function(items, props) {
            var out = [];

            if (angular.isArray(items)) {
                var keys = Object.keys(props);

                items.forEach(function(item) {
                    var itemMatches = false;

                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();
                        if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            } else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        };
    });

})();
