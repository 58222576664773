(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('PrintJobDeleteSelectedController',PrintJobDeleteSelectedController);

    PrintJobDeleteSelectedController.$inject = ['$uibModalInstance', 'ids', 'PrintJob'];

    function PrintJobDeleteSelectedController($uibModalInstance, ids, PrintJob) {
        var vm = this;
        vm.ids = ids;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PrintJob.deletePrintJobs({id: ids},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
