(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('SiteTokenController', SiteTokenController);

    SiteTokenController.$inject = ['$scope', '$state', 'SiteToken', 'SiteTokenSearch'];

    function SiteTokenController ($scope, $state, SiteToken, SiteTokenSearch) {
        var vm = this;
        
        vm.siteTokens = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            SiteToken.query(function(result) {
                vm.siteTokens = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SiteTokenSearch.query({query: vm.searchQuery}, function(result) {
                vm.siteTokens = result;
            });
        }    }
})();
