(function() {
    'use strict';
    angular
        .module('crm2PrintApp')
        .factory('ContactService', ContactService);

    ContactService.$inject = ['$resource'];

    function ContactService ($resource) {
        var resourceUrl =  '/api/contact/contact-us';

        return $resource(resourceUrl, {}, {
            'contactUs': { method:'POST' }
        });
    }
})();
