(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('AdminDashboardModalController', AdminDashboardModalController);

    AdminDashboardModalController.$inject = ['$uibModalInstance'];

    function AdminDashboardModalController ($uibModalInstance) {
        var vm = this;

        function cancel () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
