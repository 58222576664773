(function() {
    'use strict';

    angular
        .module('crm2PrintApp')
        .controller('IndustryDetailController', IndustryDetailController);

    IndustryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Industry'];

    function IndustryDetailController($scope, $rootScope, $stateParams, entity, Industry) {
        var vm = this;

        vm.industry = entity;

        var unsubscribe = $rootScope.$on('crm2PrintApp:industryUpdate', function(event, result) {
            vm.industry = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
